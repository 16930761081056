import { RequestStatus } from '../enums/commonApplication.enum'
import {
  CultureCatchUpAnswerType,
  CultureCatchUpType,
} from '../interfaces/candidate.interface'
import { axiosInstance } from './base.service'

export const getCandidateCount = async (since?: string): Promise<number> => {
  if (!since) {
    since = new Date(0).toISOString()
  }
  const url: string = `/candidate/total?start_date=${since}&end_date=${new Date().toISOString()}`
  try {
    const res = await axiosInstance.get<{ status: string; result: number }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const updateCandidateOverview = async (
  id: string,
  overview: string,
): Promise<boolean> => {
  const url: string = `/candidate/summary/admin-update?${new URLSearchParams({
    id,
  })}`
  try {
    const res = await axiosInstance.post<{ status: string }>(url, { overview })
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

export const updateStatus = async (
  id: string,
  user_status: string,
): Promise<boolean> => {
  const url: string = `/candidate/update/status?${new URLSearchParams({
    id,
    user_status,
  })}`
  try {
    const res = await axiosInstance.post<{ status: string }>(url)
    return res ? true : false
  } catch (err) {
    return false
  }
}

export const updateCandidateKeySkillsSummary = async (
  id: string,
  keySkillsSummary: string,
): Promise<boolean> => {
  const url: string = `/candidate/summary/admin-update?${new URLSearchParams({
    id,
  })}`
  try {
    const res = await axiosInstance.post<{ status: string }>(url, {
      key_skills_summary: keySkillsSummary,
    })
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }

}

// Culture Catch Up questions endpoints
export const getCandidateCultureCatchUpQuestions = async (): Promise<CultureCatchUpType[]> => {
  const url: string = `/candidate/catchup_questions/getAll`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: CultureCatchUpType[]
    }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

// Catch Up question adding for Admin
export const addNewCatchUpQuestion = async (question: {
  text: string
}): Promise<boolean> => {
  const url: string = `/candidate/catchup_questions/add`
  try {
    const res = await axiosInstance.post<{
      status: string
      result: CultureCatchUpType[]
    }>(url, question)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }

}

// Catch Up question deleting for Admin
export const deleteCatchUpQuestion = async (
  question: [{ id: string }],
): Promise<boolean> => {
  const url: string = `/candidate/catchup_questions/remove`
  try {
    const res = await axiosInstance.post<{
      status: string
      result: CultureCatchUpType[]
    }>(url, question)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

// Catch Up answering for Candidate
export const answerCatchUpQuestion = async (
  question: CultureCatchUpAnswerType[],
): Promise<boolean> => {
  const url: string = `/candidate/catchup_questions/submit_answers`
  try {
    const res = await axiosInstance.post<{
      status: string
      result: CultureCatchUpType[]
    }>(url, question)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

// Get answers for questions per candidate
export const getAnswersForQuestions = async (params: {
  hiringProcessId: string
  candidateId: string
}): Promise<any[]> => {
  const url: string = `/candidate/catchup_questions/get_answers?${new URLSearchParams(
    {
      hiringProcessId: params.hiringProcessId,
      candidateId: params.candidateId,
    },
  )}`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: any[]
    }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}
