import React, { useState } from 'react'
import styles from '../teams/styles.module.scss'
import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import FilterIconNew from '../../icons/filterNew.icon'
import SearchIcon from '../../icons/search.icon'
import CandidateCard from '../../shared/components/candidateCard/candidateCard'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import ArrowDownIcon from '../../icons/downArrow.icon'
import color from '../../scss/variables.module.scss'

export interface OverviewProfileProps {
  disabled?: boolean
  isLoading: boolean
  overview?: string
  allCandidates: any
  allCandidatesDropdownData: any
  selectedId: string
  handleSelectCandidate: (candidate: any) => void
  onUpdateOverview?: (overview: {
    overview: string
    overview_last_updated_at: string
    overview_last_updated_by: string
  }) => void
  loadMore?: () => void
  setLoadMore?: (loadMore: boolean) => void
  handleSearchCandidateValue?: (candidate: any) => void
  resetPage?: () => void
}

const CandidateFilter = ({
  isLoading,
  allCandidates,
  allCandidatesDropdownData,
  selectedId,
  handleSelectCandidate,
  handleSearchCandidateValue,
  loadMore,
  setLoadMore,
  resetPage,
}: OverviewProfileProps) => {
  const [sort, setSort] = useState<boolean>(false)

  function getSortedResult(array: any[]) {
    if (sort) {
      return array.sort((a: any, b: any) => {
        return a?.name?.localeCompare(b.name)
      })
    } else {
      return array.sort((a: any, b: any) => {
        return b?.name?.localeCompare(a.name)
      })
    }
  }

  return (
    <div className={` ${styles.organizationsFilter} `}>
      <div className={`d-flex flex-column ${styles.borderBottom} py-4 mb-4`}>
        <div>
          <h6 className='text-almostBlack'>Candidates</h6>
        </div>
        <div className='mt-4 mb-4'>
          <CustomDropdown
            getSelectedItem={(item: any) => {
              if (handleSearchCandidateValue) {
                handleSearchCandidateValue(item)
              }
              handleSelectCandidate(item)
            }}
            dataList={allCandidatesDropdownData.map((d: any) => ({
              text: d.name,
              value: d.id,
            }))}
            clear
            onClear={() => {
              if (handleSearchCandidateValue) {
                handleSearchCandidateValue('')
              }
              if (setLoadMore) {
                setLoadMore(false)
              }
              if (resetPage) {
                resetPage()
              }
            }}
            placeHolder='Search Candidates'
            icon={<SearchIcon />}
          />
        </div>
        <div className='d-flex flex-row align-items-center justify-content-between'>
          <h6 className={styles.widthLeft}>All Candidates</h6>
          <span
            className='d-flex align-items-center'
            onClick={() => {
              setSort(!sort)
              getSortedResult(allCandidates)
            }}
          >
            <FilterIconNew />
            <div className='ms-3'>Sort By</div>
          </span>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}

      {allCandidates.map((candidate: any, index: number) => (
        <div className={styles.candidateCard} key={`inside-div-2-${candidate.id}`}>
          <CandidateCard key={`card-candidate-key-${index}-${candidate.id}`}
                         fromJob={false}
                         candidate={candidate}
                         selected={candidate.id === selectedId}
                         onClick={() => handleSelectCandidate(candidate)}
          />
        </div>
      ))}
      {allCandidates.length === 0 && (
        <h6 className={'text-center mt-4'}>No candidates found</h6>
      )}
      <span
        className='d-flex align-items-center mt-3 mb-5'
        onClick={() => {
          if (loadMore) {
            loadMore()
          }
        }}
        style={{ cursor: 'pointer' }}
      >
          <h6 className='me-4 mt-2 pointer text-tertiary-color'>
            Load More
          </h6>
          <ArrowDownIcon color={color.teritaryColor500} />
        </span>
    </div>
  )
}

export default CandidateFilter
