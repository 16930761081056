import React, { useCallback, useEffect, useState } from 'react'
import { HiringProcess } from '../../shared/interfaces/hiring.interface'
import styles from './Backdrop.module.scss'
import { getCandidateJobs } from '../../shared/services/hiring.service'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import { HiringStage } from '../../shared/enums/hiring.enum'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

type HistoryModalProps = {
  hiringData: HiringProcess | null
}

type HistoryRes = {
  id: string
  job_title: string
  job_posted_date: string
  step_in_hiring_process: string
  organisation_name: string
}

function HistoryModal({ hiringData }: HistoryModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [historyData, setHistoryData] = useState<HistoryRes[]>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  /**
   * Fetch history data of hiring process
   * Exclude Screening step and current job
   * @param {hiring_process_id} params
   * @returns {Promise<void>}
   */
  const fetchHistoryData = useCallback(
    async (params: { hiring_process_id: string }) => {
      setIsLoading(true)
      try {
        const res = await getCandidateJobs(params)
        if (res.length > 0) {
          const updatedRes = res.filter((item) => item.data.id !== hiringData?.job_id)
          const filteredRes = updatedRes.reduce((accumulator, item) => {
            // Check if step_in_hiring_process is not equal to 'Screening'
            if (item.process_step !== HiringStage.Screening) {
              accumulator.push({
                id: item.data.id,
                job_title: item.data.title,
                job_posted_date: item.data.created_at,
                step_in_hiring_process: item.process_step,
                organisation_name: item.data.__organization__.name,
              })
            }
            return accumulator
          }, [])

          setHistoryData(filteredRes)
          setIsLoading(false)
        } else {
          setHistoryData([])
          setIsLoading(false)
        }
      } catch (error: any) {
        setIsLoading(false)
        setErrorModel(true)
        setErrorMessage(error.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )


  useEffect(() => {
    if (hiringData) {
      fetchHistoryData({ hiring_process_id: hiringData.id })
    }
  }, [fetchHistoryData, hiringData])

  return (
    <>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        {historyData.map((job, index) => (
          <div className={styles.card} key={`history-main-div-${index}`}>
            <h6 className={'text-normal'}>
              Job Title :{' '}
              <span className={'gray-color-text text-bold'}>
                {job.job_title}
              </span>
            </h6>
            <h6 className={'text-normal'}>
              Organisation :{' '}
              <span className={'gray-color-text text-bold'}>
                {job.organisation_name}
              </span>
            </h6>
            <h6 className={'text-normal'}>
              Job Posted Date :{' '}
              <span className={'gray-color-text text-bold'}>
                {job && job.job_posted_date &&
                  (() => {
                    const dateObj = new Date(job.job_posted_date)
                    const day = dateObj.getDate().toString().padStart(2, '0')
                    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
                    const year = dateObj.getFullYear()
                    return `${day}-${month}-${year}`
                  })()
                }

              </span>
            </h6>
            <h6 className={'text-normal'}>
              Step in hiring process :{' '}
              <span className={'gray-color-text text-bold'}>
                {job.step_in_hiring_process}
              </span>
            </h6>
          </div>
        ))}
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default HistoryModal
