import React from 'react'

interface Props {
  size?: number
  color?: string
}

const FilterUpIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 24}
      height={size ?? 24}
      fill='none'
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M4 6h7m-7 6h7m-7 6h9m2-9 3-3m0 0 3 3m-3-3v12'
      />
    </svg>
  )
}

export default FilterUpIcon
