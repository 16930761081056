import CustomButton from '../customButton/customButton'
import CustomInputField from '../customInputField/customInputField'
import { Form, Formik } from 'formik'
import RecycleBinIcon from '../../../icons/recycleBinIcon'
import { useEffect, useMemo, useState } from 'react'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import { useNavigate } from 'react-router-dom'
import styles from '../../../shared/components/filterModal/styles.module.scss'
import loadingStyles from '../customLoadingSpinner/loadingSpinner.module.scss'
import YupErrorStyles from '../filterModal/styles.module.scss'
import { Auth } from 'aws-amplify'
import * as Yup from 'yup'

export type UpdatePasswordProps = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type CustomModalProps = {
  onCloseModal: (value: boolean) => void;
};

const UpdatePassword = ({ onCloseModal }: CustomModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [err, setErr] = useState({ status: false, text: '' })
  const navigate = useNavigate()

  const initialVal: UpdatePasswordProps = useMemo(() => {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  }, [])

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .notOneOf([Yup.ref<string>('currentPassword')], 'New password cannot be the same as the current password')
      .min(8, 'Password must be at least 8 characters')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .notOneOf([Yup.ref<string>('currentPassword')], 'Confirm password cannot be the same as the current password')
      .required('Please confirm your password')
      .oneOf([Yup.ref<string>('newPassword')], 'Passwords do not match.'),
  })

  const handleOkClick = async () => {
    await Auth.signOut()
    navigate('/')
  }

  const showAlert = async () => {
    window.alert('Password has been updated successfully')
    handleOkClick()
  }

  const handleSubmit = async (values: UpdatePasswordProps) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setIsLoading(true)
      await Auth.changePassword(user, values.currentPassword, values.newPassword)
      setIsLoading(false)
      await showAlert()
    } catch (error: any) {
      setIsLoading(false)
      setErr({ status: true, text: 'Current password is incorrect' })
      error.message === 'Attempt limit exceeded, please try after some time.' ?
        setErr({ status: true, text: 'Attempt limit exceeded, please try after some time' }) :
        setErr({ status: true, text: 'Current password is incorrect' })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setErr({ status: false, text: '' })
  }, [currentPassword])


  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik initialValues={initialVal} enableReinitialize={true} onSubmit={handleSubmit}
                validationSchema={validationSchema}>
          {({ values, setFieldValue, errors }) => (
            <Form>
              <div>
                <div className={`mt-3 justify-content-between ${styles.borderBottomLight}`}>
                  <CustomInputField type='password' name={'currentPW'} placeholder={'Current Password *'}
                                    className={'mt-2'} value={values.currentPassword} onChange={(event: any) => {
                                      setCurrentPassword(event.target.value)
                                      setFieldValue('currentPassword', event.target.value)
                                    }} />
                  {errors.currentPassword && <div className={YupErrorStyles.yupError}>{errors.currentPassword}</div>}
                  {err.status && <div className={YupErrorStyles.yupError}>{err.text}</div>}
                </div>
                <div className={`mt-4 justify-content-between ${styles.borderBottomLight}`}>
                  <CustomInputField type='password' name={'newPW'} placeholder={'New Password *'} className={'mt-2'}
                                    value={values.newPassword} onChange={(event: any) => {
                                      setNewPassword(event.target.value)
                                      setFieldValue('newPassword', event.target.value)
                                    }} />
                  {errors.newPassword && <div className={YupErrorStyles.yupError}>{errors.newPassword}</div>}
                  <CustomInputField type='password' name={'confirmPW'} placeholder={'Confirm New Password *'}
                                    className={'mt-2'} value={values.confirmPassword} onChange={(event: any) => {
                                      setFieldValue('confirmPassword', event.target.value)
                                    }} />
                  {errors.confirmPassword && <div className={YupErrorStyles.yupError}>{errors.confirmPassword}</div>}
                </div>
                <div className={'d-flex justify-content-between mt-5'}>
                  <div>
                    <CustomButton
                      text={'Discard'}
                      icon={<RecycleBinIcon />}
                      iconSide={'left'}
                      className={styles.discardBtn}
                      onClick={() => onCloseModal(false)}
                    />
                  </div>
                  <div>
                    <CustomButton type={'submit'} loading={isLoading} text={'Update Password'}
                                  className={styles.modalBtn}
                                  disabled={isLoading || err.status || Object.keys(errors).length !== 0 || Object.values(values).every(value => value === '')} />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default UpdatePassword
