import React, { useEffect, useMemo, useState } from 'react'

import adminLogo from '../../../images/BBB Logo_Admin.jpg'
import styles from './TopNavigation.module.scss'

import AvatarDropdown, { DropdownDataList } from '../avatarDropdown/avatarDropdown'
import DownArrowIcon from '../../../icons/downArrow.icon'
import ManIcon from '../../../icons/man.icon'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router'
import { motion } from 'framer-motion'
import HamburgerIcon from '../../../icons/hamburger.icon'
import SettingsIcon from '../../../icons/settings.icon'
import { UserRole } from '../../enums/auth.enum'
import { getUserRole } from '../../services/auth.service'
import CustomModal from '../customModal/customModal'
import AccountProfile from './accountProfile'
import UpdatePassword from './updatePassword'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type TopNaveProps = {
  tabValue?: string
}

const dataList = [
  { value: 'accountProfile', text: 'Account Profile' },
  { value: 'updatePassword', text: 'Update Password' },
  { value: 'signOut', text: 'Sign Out' },
]

function TopNavigation({ tabValue }: TopNaveProps) {
  const navigate = useNavigate()

  // State
  const [tab, setTab] = useState(tabValue ?? '')
  const [role, setRole] = useState<string>('')
  const [fullName, setFullName] = useState('')
  const [modal, setModal] = useState(false)
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // Get the user role
  useEffect(() => {
    getUserRole().then(setRole)
  }, [])

  // The navigation tab options
  const navigationList = useMemo(
    () =>
      [
        { value: '/dashboard', text: 'Dashboard' },
        { value: '/allJobs', text: 'Roles' },
        { value: '/candidates', text: 'Candidates' },
        { value: '/organization', text: 'Organisations' },
        role !== UserRole.StaffAdmin ? { value: '/needAnalysis', text: 'Needs Analysis' } : { value: '', text: '' },
        // Only include the following tab if the user is not a Staff Admin
        role !== UserRole.StaffAdmin
          ? { value: '/teams', text: 'Team' }
          : { value: '', text: '' },
      ].filter((i) => i.value !== ''),
    [role],
  )

  /**
   * This function is used to handle the dropdown click
   * @param item The dropdown item
   * @returns void
   */
  const handleDropDownClick = async (item: DropdownDataList): Promise<void> => {
    if (item.value === 'signOut') {
      await Auth.signOut()
      navigate('/')
    } else if (item.value === 'accountProfile') {
      setModal(true)
    } else if (item.value === 'updatePassword') {
      setUpdatePasswordModal(true)
    }
  }

  /**
   * This function is used to navigate to the tab's page
   * @param value The tab value to navigate to
   * @returns void
   */
  const handleTab = (value: string): void => {
    setTab(value)

    navigate(value)
  }

  useEffect(() => {
    async function fetchUserData() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const attributes = user.attributes

        // Assuming 'given_name' and 'family_name' are the attribute names for first name and last name
        const firstName = attributes.given_name
        const lastName = attributes.family_name

        const fullName = `${firstName} ${lastName}`
        setFullName(fullName)
      } catch (error: any) {
        setErrorModel(true)
        setErrorMessage(error.message)
      }
    }

    fetchUserData()
  }, [])

  return (
    <div className={styles.header}>
      <div
        className={'d-flex align-items-center justify-content-between w-100'}
      >
        <div className={styles.logo}>
          <img src={adminLogo} alt='react logo' className={styles.logoImage} />
        </div>
        <div className={styles.mobileLogo}>
          <img src={adminLogo} alt={'mobileLogo'} style={{ width: '100px' }} />
        </div>
        <div className={'d-flex align-items-center '}>
          <div className={'d-flex align-items-center'}>
            {navigationList.map((value, index) => (
              <div className={styles.sideMenuButton} key={index}>
                <h6
                  onClick={() => handleTab(value.value)}
                  className={`text-normal text-tertiary-color pointer ms-5 me-5 `}
                >
                  {value.text}
                  {tab === `${index}` && (
                    <motion.div
                      className={styles.indicator}
                      layoutId={`nav-${index}`}
                      id={`nav-${index}`}
                    />
                  )}
                </h6>
              </div>

            ))}
            {
              role === UserRole.GlobalAdmin && (<div onClick={() => navigate('/hardSkillAssessment')}>
                <SettingsIcon />
              </div>)
            }

          </div>
          <div className={styles.leftBorder}>
            <div className={styles.dropDown}>
              <AvatarDropdown
                dataList={dataList}
                name={'topNav'}
                getSelectedItem={(item: DropdownDataList) =>
                  handleDropDownClick(item)
                }
              >
                <div className={'d-flex align-items-center '}>
                  <h6 className={'text-almostBlack me-3 mt-2'}>{fullName.split(' ').slice(0, 2).join(' ')}</h6>
                  <ManIcon />
                  <div className={'ms-md-2'}>
                    <DownArrowIcon />
                  </div>
                </div>
              </AvatarDropdown>
            </div>
          </div>
          <div className={styles.mobileMenu}>
            <AvatarDropdown
              icon={<HamburgerIcon />}
              dataList={navigationList}
              getSelectedItem={(item: DropdownDataList) =>
                handleTab(item.value)
              }
            />
          </div>
        </div>
      </div>
      {modal && (
        <CustomModal
          open={modal}
          onCloseModal={(val: any) => setModal(val)}
          title={'Account Profile'}
        >
          <AccountProfile onCloseModal={(val) => setModal(val)} />
        </CustomModal>
      )}
      {updatePasswordModal && (
        <CustomModal
          open={updatePasswordModal}
          onCloseModal={(val: any) => setUpdatePasswordModal(val)}
          title={'Update Password'}
        >
          <UpdatePassword onCloseModal={(val) => setUpdatePasswordModal(val)} />
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default TopNavigation
