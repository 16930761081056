import React from 'react'

interface Props {
  size?: number
  color?: string
}

const AssessmentResultIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 24}
      height={size ?? 24}
      fill='none'
      viewBox={'0 0 24 24'}
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2M9 5a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2m-6 7h.01M13 12h2m-6 4h.01M13 16h2'
      />
    </svg>
  )
}

export default AssessmentResultIcon
