import styles from './styles.module.scss'
import colors from '../../../scss/variables.module.scss'
import React, { useEffect, useMemo, useState } from 'react'
import HeartIcon from '../../../icons/heart.icon'
import LoadingService from '../../services/loading.service'
import { getHiringProcessCandidateData } from '../../services/hiring.service'
import { getMasterData } from '../../services/masterData.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import { WorkCommitment } from '../../enums/job.enum'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface PreferencesProps {
  candidateId: string
}

const Preferences = ({ candidateId }: PreferencesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tags, setTags] = useState<string[]>([])
  const [masterData, setMasterData] = useState<any[]>([])
  const [roleList, setRoleList] = useState<any[]>([])
  const [userData, setUserData] = useState<any>(null)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )

  useEffect(() => {
    loadingService.await(getHiringProcessCandidateData({ candidateId })).then(setUserData).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }, [candidateId, loadingService])

  useEffect(() => {
    loadingService.await(getMasterData()).then(setMasterData).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masterData) {
      setRoleList(masterData.filter((fd: any) => fd.category === 'finance_jobs').map((item: any) => ({
        value: item.id,
        text: item.value,
      })))
    }
  }, [masterData])

  useEffect(() => {
    if (userData) {
      const tags = []
      if (userData?.hours_weekly) tags.push(userData.hours_weekly + ' hrs/week')
      if (userData.commitment) tags.push(userData.commitment === WorkCommitment.PermanentFullTime ? 'Full Time' : WorkCommitment.PermanentPartTime ? 'Part Time' : userData.commitment === WorkCommitment.Casual ? 'Casual' : 'Unknown Commitment')
      if (userData.work_type) tags.push(userData.work_type)
      if (userData.on_site_days && userData.work_type === 'Hybrid') tags.push(userData.on_site_days >= 3 ? `More than two days` : userData.on_site_days > 1 ? `${userData.on_site_days} days on-site` : `${userData.on_site_days} day on-site`)
      if (userData.pay_hourly) tags.push('$' + userData.pay_hourly + '/hr')
      if (userData.pay_annual) tags.push('$' + userData.pay_annual + '/yr')
      // Make sure the notice period is not in days
      if (userData.notice_period_days) {
        const noticePeriodInWeeks = Math.ceil(userData.notice_period_days / 7)
        tags.push(`Notice Period: ${noticePeriodInWeeks} week${noticePeriodInWeeks > 1 ? 's' : ''}`)
      }
      if (userData.notice_period_days === 0) tags.push('Available Immediately')
      if (userData.suburb) tags.push('Lives in ' + userData.suburb)
      if (userData.commute_minutes && userData.work_type !== 'Off-Site') {
        if ((parseInt(userData.commute_minutes) >= 60)) {
          /**
           * Calculates the number of hours it takes for the user to commute based on the number of minutes.
           * @param {number} userData.commute_minutes - The number of minutes it takes for the user to commute.
           * @returns {number} The number of hours it takes for the user to commute.
           */
          const hours = Math.floor(userData.commute_minutes / 60)
          const minutes = userData.commute_minutes % 60
          if (minutes === 0) tags.push('Commute for ' + `${hours} hour`)
          else {
            tags.push(
              'Commute for ' +
              `${hours} hours ${minutes} minutes`,
            )
          }
        } else {
          tags.push(
            'Commute for ' +
            `${userData.commute_minutes} minutes`,
          )
        }
      }
      if (userData.preferred_role) tags.push('Preferred Role: ' + roleList?.filter((fd) => fd.value === userData.preferred_role)[0]?.text)
      setTags(tags)
    }
  }, [loadingService, userData, roleList])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
        >
          <div className={'d-flex align-items-center mb-3'}>
            <HeartIcon color={colors.blackColor} />
            <h4 className={'text-almostBlack ms-3 text-bolder'}>Preferences</h4>
          </div>
        </div>
        <div className={'d-flex align-items-center w-100 flex-wrap mt-3'}>
          {tags && (
            <div className='d-flex mt-3 flex-wrap'>
              {tags.map((value, index) => (
                <div key={`preferences-main-div-${index}`} className={styles.payableTag}>
                  <h6>{value}</h6>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default Preferences
