import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import CalenderIcon from '../../../icons/calender.icon'
import CertificateIcon from '../../../icons/certificate.icon'
import CertificateProfileIcon from '../../../icons/certificateProfile.icon'
import { getMasterData } from '../../services/masterData.service'
import dayjs from 'dayjs'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface CertificateProps {
  certifications?: {
    certification: string
    organization?: string
    issue_date?: string
    reference_id?: string
  }[]
}

function Certificate({ certifications }: CertificateProps) {
  const [certificateLevels, setCertificationLevels] = useState<any[]>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    async function fetchData() {
      try {
        const md = await getMasterData()
        setCertificationLevels(md.filter((fd: any) => fd.category === 'licences_and_certifications'))
      } catch (error: any) {
        setErrorModel(true)
        setErrorMessage(error.message)

      }
    }

    fetchData()

  }, [])
  return (
    <div>
      <div className={`d-flex align-items-center justify-content-between `}>
        <div className={'d-flex align-items-center mb-3'}>
          <CertificateProfileIcon />
          <h4 className={'text-almostBlack ms-3 text-bolder'}>Certificates & Licences</h4>
        </div>
      </div>
      {(certifications ?? []).map((value, index: number) => (
        <div className={styles.educationCard} key={index}>
          <div className={'d-flex align-items-center'}>
            <CertificateIcon />
            <div className={'ms-4'}>
              <h6 className={'text-bold gray-color-text'}>
                {certificateLevels?.filter((fd: any) => fd.id === value.certification)[0]?.value ?? value.certification}
              </h6>
              <div className={'d-flex mt-1'}>
                {new Date(value.issue_date ?? '').getFullYear() === 1970 ? <></> : <CalenderIcon />}
                <h6
                  className={`ms-2 gray-color-text text-normal`}
                >
                  {new Date(value.issue_date ?? '').getFullYear() === 1970 ? '' : `${dayjs(value.issue_date).format('MMM YYYY')}`}
                </h6>
                <h6
                  className={`ms-2 gray-color-text text-normal ${styles.borderRight}`}
                >
                  {value.organization ? value.organization : ''}
                </h6>
                <h6 className={'gray-color-text ms-2 ms-lg-4 '}>
                  Ref No
                  <span className={'ms-2 text-normal'}>
                    {value.reference_id ? value.reference_id : '-'}
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ))}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Certificate
