/**
 * A searchable dropdown component that allows users to select an item from a list of options.
 * @param id - The unique identifier for the dropdown component.
 * @param placeHolder - The placeholder text to display when no option is selected.
 * @param disabled - Whether the dropdown is disabled or not.
 * @param children - The child components to render within the dropdown.
 * @param selectedListIcon - The icon to display next to the selected item.
 * @param listIcon - The icon to display next to each item in the dropdown list.
 * @param dataList - The list of options to display in the dropdown.
 * @param selectedValue - The currently selected option.
 * @param bold - Whether to display the selected item in bold.
 * @param inputFieldStyles - The CSS styles to apply to the input field.
 * @param name - The name of the input field.
 * @param enableSearch - Whether to enable search functionality.
 * @param getSelectedItem - The function to call when an item is selected.
 * @param sortDropdown - Whether to sort the dropdown options alphabetically.
 * @param maxResults - The maximum number of results to display in the dropdown.
 * @param icon - The icon to display within the input field.
 * @param iconOn - The position of the icon within the input field.
 * @param defaultStyles - Whether to apply default CSS styles to the dropdown.
 * @param removeGutter - Whether to remove the gutter between the input field and the dropdown.
 * @param onFocus - The function to call when the input field is focused.
 * @param onBlur - The function to call when the input field loses focus.
 * @param onChange - The function to call when the input field value changes.
 * @param searchedValue - The value to search for in the dropdown.
 * @param isDisabled - Whether the dropdown is disabled or not.
 * @param dropUp - Whether to display the dropdown above the input field.
 * @param handleSearch - The function to call when the search button is clicked.
 */
import React, {
  ChangeEvent,
  createRef,
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useClickOutside } from '../../../shared/hooks/clickOutside.hook'
import styles from './customDropdown.module.scss'
import { v4 } from 'uuid'
import SearchIcon from '../../../icons/search.icon'
import CloseIcon from '../../../icons/close.icon'

export type DropdownDataList = {
  text: string
  value: string
  groupBy?: string
  id?: string
}

type CustomDropdownProps = {
  id?: string
  placeHolder?: string
  children?: React.ReactNode
  selectedListIcon?: ReactNode
  listIcon?: ReactNode
  disabled?: boolean
  dataList?: DropdownDataList[]
  selectedValue?: DropdownDataList
  inputFieldStyles?: string
  bold?: boolean
  name?: string
  enableSearch?: boolean
  getSelectedItem?: (item: DropdownDataList, id: string) => void
  sortDropdown?: boolean
  maxResults?: number
  icon?: ReactNode
  iconOn?: 'start' | 'end'
  removeGutter?: boolean
  onFocus?: (id: string) => void
  onBlur?: (id: string) => void
  defaultStyles?: boolean
  onChange?: (text: string) => void
  searchedValue?: string
  isDisabled?: boolean
  dropUp?: boolean
  team?: boolean
  downArrow?: boolean
  // Presence of this function will override dropdown behaviour
  handleSearch?: (text: string) => void
  onClear?: () => void
}

const SearchDropdown = ({
  id = v4(),
  placeHolder,
  team = false,
  disabled = false,
  children,
  selectedListIcon,
  listIcon,
  dataList = [],
  selectedValue,
  bold = true,
  inputFieldStyles,
  name = 'dropdownInput',
  enableSearch = false,
  getSelectedItem,
  sortDropdown = false,
  maxResults = -1,
  icon,
  iconOn = 'start',
  defaultStyles = true,
  removeGutter = false,
  onFocus,
  onBlur,
  onChange,
  searchedValue,
  isDisabled,
  dropUp = false,
  handleSearch,
  onClear,
}: CustomDropdownProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [active, setActive] = useState(false)
  const [cursor, setCursor] = useState(-1)
  const [inputValue, setInputValue] = useState(selectedValue?.text ?? '')
  const [filteredData, setFilteredData] = useState(dataList)
  const [isCleared, setIsCleared] = useState(false)
  const [openContainer, setOpenContainer] = useState(false)
  const [selected, setSelected] = useState<DropdownDataList>(
    enableSearch
      ? { text: '', value: '' }
      : selectedValue
        ? selectedValue
        : { text: placeHolder ?? '', value: '' },
  )
  const itemRefs = useRef<RefObject<HTMLDivElement>[]>([])
  const width = useRef(0)
  itemRefs.current = dataList.map(
    (_, i) => itemRefs.current[i] ?? createRef<HTMLDivElement>(),
  )

  useEffect(() => {
    width.current = window.innerWidth
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onFocus?.(id)
    if (!disabled) {
      if (handleSearch) {
        handleSearch(inputValue)
        setIsCleared(false)
      } else {
        if (active) {
          setActive(false)
          setIsCleared(false)
        } else {
          setActive(true)
          setIsCleared(false)
        }
      }
    }

    // scroll dropdown to current selected element and make sure keyUp keyDown events work from the selected element
    if (itemRefs && itemRefs.current) {
      const index = itemRefs.current.findIndex(
        (item) => item?.current?.innerText === selected?.text,
      )

      if (index > -1) {
        itemRefs.current[index]?.current?.scrollIntoView()
        setCursor(index)
      }
    }
  }


  // Add this useEffect to handle clearing the input and selected item
  useEffect(() => {
    if (isCleared) {
      // Create a custom synthetic event with the necessary properties
      const syntheticEvent = {
        stopPropagation: () => {
        }, // You can add more methods if needed
      } as React.MouseEvent<HTMLButtonElement, MouseEvent>

      handleClick(syntheticEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCleared])


  function getSortedResult(array: DropdownDataList[]) {
    return array.sort((a: DropdownDataList, b: DropdownDataList) => {
      return a?.text.localeCompare(b.text)
    })
  }

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (width.current > 600) {
      event.target.select()
    }
    if (!disabled) {
      setOpenContainer(true)
    }
    event.target.autocomplete = 'off'
    if (!selected) {
      let dropdown = dataList
      if (sortDropdown) {
        dropdown = getSortedResult(dataList)
      }
      if (maxResults > 0) {
        dropdown = dropdown.slice(0, maxResults)
      }
      setFilteredData(dropdown)
    }
  }

  const handleBlur = () => {
    onBlur?.(id)
  }

  useEffect(() => {
    let dropdown = dataList
    if (sortDropdown) {
      dropdown = getSortedResult(dropdown)
    }
    if (maxResults > 0) {
      dropdown = dropdown.slice(0, maxResults)
    }
    setFilteredData(dropdown)
  }, [dataList, maxResults, sortDropdown])

  const onchange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (onChange) onChange(value)
    let filterData = dataList
    setInputValue(value)
    if (value) {
      if (sortDropdown) {
        filterData = getSortedResult(filterData)
      }

      filterData = filterData.filter((item) =>
        item.text?.toLowerCase().startsWith(value?.toLowerCase()),
      )
    }
    if (value === '') {
      setSelected({ text: '', value: '' })
      setInputValue('')
    }

    if (sortDropdown) {
      filterData = getSortedResult(filterData)
    }

    if (maxResults > 0) {
      filterData = filterData.slice(0, maxResults)
    }
    setFilteredData(filterData)
  }

  const handleSelectItem = (item: DropdownDataList) => {
    setSelected(item)
    setInputValue(item.text)
    setActive(!active)
    getSelectedItem?.(item, id)

    let dropdown = dataList
    if (sortDropdown) {
      dropdown = getSortedResult(dropdown)
    }
    if (maxResults > 0) {
      dropdown = dropdown.slice(0, maxResults)
    }
    setFilteredData(dropdown)
    setOpenContainer(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = event.key
    let index = cursor
    if (filteredData && filteredData.length) {
      if (key === 'ArrowDown') {
        index = cursor < filteredData.length - 1 ? cursor + 1 : 0
        setCursor(index)
        setSelected(filteredData[index])
        setInputValue(filteredData[index].text)
        if (itemRefs && itemRefs.current) {
          itemRefs.current
            .find(
              (item) => item?.current?.innerText === filteredData[index].text,
            )
            ?.current?.scrollIntoView()
        }
      } else if (key === 'ArrowUp') {
        index = cursor > 0 ? cursor - 1 : filteredData.length - 1
        setCursor(index)
        setSelected(filteredData[index])
        setInputValue(filteredData[index].text)
        if (itemRefs && itemRefs.current) {
          itemRefs.current
            .find(
              (item) => item?.current?.innerText === filteredData[index].text,
            )
            ?.current?.scrollIntoView()
        }
      } else if (key === 'Enter') {
        const selectedItemIndex = filteredData.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(selected),
        )
        handleSelectItem(
          index > -1
            ? filteredData[index]
            : selectedItemIndex > -1
              ? filteredData[selectedItemIndex]
              : { text: '', value: '' },
        )
        index = -1
        setCursor(index)
        if (inputRef) {
          inputRef.current?.blur()
        }
      }
    }
  }

  const domNode = useClickOutside(() => {
    if (active) {
      setActive(false)
      setInputValue(selected.text)
      handleSelectItem(selected)
    }
  })

  const handleSearchedResults = (
    originalText: string,
    searchedText: string,
  ): React.ReactElement => {
    const startIndex = originalText
      .toLocaleLowerCase()
      .trim()
      .indexOf(searchedText.toLocaleLowerCase().trim())

    if (startIndex < 0) return <>{originalText}</>

    return (
      <>
        {originalText.substring(0, startIndex)}
        <b>
          {originalText.substring(startIndex, startIndex + searchedText.length)}
        </b>
        {originalText.substring(
          startIndex + searchedText.length,
          originalText.length,
        )}
      </>
    )
  }
    
  return (
    <div
      data-test-id='customDropdown'
      ref={domNode}
      className={[
        styles.customDropdown,
        openContainer ? styles.open : styles.closed,
        removeGutter ? styles.removeGutter : '',
      ].join(' ')}
    >
      <div className={styles.customDropdownContent}>
        <div className={styles.searchFieldContainer}>
          <input
            ref={inputRef}
            type='text'
            id={id}
            name={name}
            className={[
              defaultStyles ? styles.searchFieldDefault : styles.customField,
              iconOn === 'start'
                ? icon && styles.iconSpaceStart
                : icon && styles.iconSpaceEnd,
              inputFieldStyles,
              !bold ? 'not-bold' : '',
            ].join(' ')}
            autoComplete='off'
            aria-autocomplete='none'
            disabled={isDisabled}
            value={inputValue}
            onFocus={handleFocus}
            onChange={onchange}
            onBlur={handleBlur}
            placeholder={inputValue ?? placeHolder}
            readOnly={disabled}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          {!inputValue && (
            <label className={[styles.icon, styles.iconStart].join(' ')}>
              <SearchIcon />
            </label>
          )}
          <label
            htmlFor={id}
            className={[
              styles.formLabel,
              defaultStyles
                ? styles.labelSpaceStartWithIconDefault
                : styles.labelSpaceStartWithIcon,

              defaultStyles
                ? styles.labelSpaceStartDefault
                : styles.labelSpaceStart,
            ].join(' ')}
          >
            {!inputValue && (
              <h6 className={`light-text text-normal ${team ? styles.teamPlaceHolderElipse : styles.placeHolderEclipse}`}>{placeHolder}</h6>
            )}
          </label>
          <button className={styles.searchButton} onClick={handleClick}>
            <h6>Search</h6>
          </button>
          {children && (
            <label
              htmlFor={id}
              className={[
                styles.icon,
                defaultStyles ? styles.iconDefault : null,
                iconOn === 'start' ? styles.iconStart : styles.iconEnd,
              ].join(' ')}
            >
              <h6>{children}</h6>
            </label>
          )}
        </div>
        {/* <AnimatePresence exitBeforeEnter initial={false}> */}
        {inputValue.length > 0 &&
          <label
            htmlFor={id}
            className={[
              styles.closeIcon,
              styles.r70,
            ].join(' ')}
            onClick={() => {
              setSelected({ text: '', value: '' })
              setInputValue('')
              getSelectedItem?.({ text: '', value: '' }, '')
              setIsCleared(true)
              onClear?.()
            }
            }
          >
            <CloseIcon />
          </label>
        }
        {active && (
          <div
            className={[
              defaultStyles
                ? styles.customDropdownBodyDefault
                : styles.customDropdownBody,
              dropUp ? styles.dropUp : styles.customDropdownBody,
            ].join(' ')}
          >
            <div className={styles.customDropdownBodyInnerBox}>
              {children || (filteredData && filteredData.length) ? (
                filteredData.map((item: DropdownDataList, index) => {
                  return (
                    <div key={v4()} ref={itemRefs.current[index]}>
                      {item.groupBy && (
                        <h6 className='mt-5 mb-2'>{item.groupBy}</h6>
                      )}
                      <div
                        data-test-id='bodyItem'
                        key={v4()}
                        className={[
                          styles.customDropdownBodyItem,
                          item.text === selected.text ? styles.selected : '',
                        ].join(' ')}
                        onClick={() => {
                          handleSelectItem(item)
                        }}
                      >
                        <div className='d-flex'>
                          {item.text === selected.text ? (
                            <div className='me-3'>{selectedListIcon}</div>
                          ) : (
                            <div className='me-3'>{listIcon}</div>
                          )}
                          <h6>
                            <span>
                              {searchedValue
                                ? handleSearchedResults(
                                  item.text,
                                  searchedValue,
                                )
                                : item.text}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <p className='pt-3 pb-2'>No Matching data...</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchDropdown
