import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import styles from './customButton.module.scss'

type ButtonProps = {
  text?: string
  type?: 'button' | 'submit' | 'reset'
  icon?: ReactElement
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  className?: string
  variant?:
  | 'primary'
  | 'secondary'
  | 'special'
  | 'danger'
  | 'success'
  | 'transparent'
  loading?: boolean
  outlined?: boolean
  uppercase?: boolean
  iconSide?: 'left' | 'right'
  onClick?: () => void
  subText?: string
  id?: string
  download?: boolean
  colorTheme?: string
}

const CustomButton: React.FC<ButtonProps> = ({
  text = '',
  type = 'button',
  icon,
  disabled = false,
  size = 'medium',
  className,
  variant = 'primary',
  loading = false,
  outlined = false,
  uppercase = false,
  iconSide = 'left',
  onClick,
  subText = '',
  id,
  download = false,
}) => {
  const [isLoading, setIsLoading] = useState(loading ?? false)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const variantClass = useMemo<string>(() => {
    switch (variant) {
      case 'secondary':
        return !outlined ? styles.secondary : styles.secondaryOutlined

      case 'special':
        return !outlined ? styles.special : styles.specialOutlined
      case 'danger':
        return !outlined ? styles.danger : styles.dangerOutlined
      case 'success':
        return !outlined ? styles.success : styles.successOutlined
      case 'transparent':
        return !outlined ? styles.transparent : styles.transparentOutline
      default:
        return !outlined ? styles.primary : styles.primaryOutlined
    }
  }, [outlined, variant])

  const sizeClass = useMemo<string>(() => {
    switch (size) {
      case 'small':
        return styles.small
      case 'large':
        return styles.large
      default:
        return styles.medium
    }
  }, [size])

  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${iconSide === 'right' ? styles.flip : null} ${
        download ? styles.widthBtn : null
      } ${styles.customButton} 
       ${variantClass} ${sizeClass} `}
    >
      {icon && !isLoading && (
        <div className={styles.customButtonIcon}>{icon}</div>
      )}

      {isLoading && (
        <div className={styles.customButtonIcon}>
          <div className='spinner-border' role='status'></div>
        </div>
      )}
      <div className={styles.buttonText}>
        <h6
          className={`${
            download ? styles.downloadText : styles.customButtonText
          } ${uppercase ? styles.uppercase : ''} `}
        >
          {text}
        </h6>
        <div className={`${styles.customButtonSubText}`}>{subText}</div>
      </div>
    </button>
  )
}

export default CustomButton
