import React, { useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import CandidateFilter from './candidateFilter'
import LocationIcon from '../../icons/location.icon'
import BagIcon from '../../icons/bag.icon'
import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import CustomButton from '../../shared/components/customButton/customButton'
import Overview from '../../shared/components/candidateInfo/overview'
import KeySkill from '../../shared/components/candidateInfo/keySkill'
import Expertise from '../../shared/components/candidateInfo/expertise'
import Education from '../../shared/components/candidateInfo/education'
import Certificate from '../../shared/components/candidateInfo/certificate'
import {
  getAllCandidatesDropdownDataList,
  getAllCandidatesList,
  getCandidateDetails,
  getCandidateFiles,
} from '../../shared/services/hiring.service'
import LoadingService from '../../shared/services/loading.service'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { getRefereeComments } from '../../shared/services/job.service'
import { Responses } from '../../shared/components/candidateInfo/referenceCheck'
import CustomModal from '../../shared/components/customModal/customModal'
import ReviewModal from '../../shared/components/candidateInfo/reviewModal'
import QuoteIcon from '../../icons/quote.icon'
import { updateStatus } from '../../shared/services/candidate.service'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import JPassInvoice from '../pdf/pdf'
import { BlobProvider } from '@react-pdf/renderer'
import ResumeIcon from '../../icons/resume.icon'
import fileStyles from '../../shared/components/candidateInfo/styles.module.scss'
import resumeStyles from '../dashboard/Backdrop.module.scss'
import { UploadedFile } from '../../shared/types/file.model'
import WorkExperience from '../../shared/components/candidateInfo/workExperience'
import Preferences from '../../shared/components/candidateInfo/preferences'
import { getMasterData } from '../../shared/services/masterData.service'
import Assessment from '../../shared/components/candidateInfo/assesmenmtResult'
import colors from '../../scss/variables.module.scss'
import AvataImage from '../../images/avatar.png'
import { deleteCandidate } from '../../shared/services/user.service'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import ConfirmBannerModal from '../../shared/components/errorBannerModal/confirmBannerModal'
import { CandidateStatus } from '../../shared/interfaces/candidate.interface'
import SuccessBannerModal from '../../shared/components/errorBannerModal/successBannerModal'
import { getUserRole } from '../../shared/services/auth.service'
import { UserRole } from '../../shared/enums/auth.enum'
import EmailIcon from '../../icons/email.icon'
import PhoneIcon from '../../icons/phone.icon'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'

function Candidates() {
  const navigate = useNavigate()
  const [isDisabled] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [onUpdating, setOnUpdating] = useState<boolean>(false)
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )
  const [active, setActive] = useState<boolean>(false)
  const [referees, setReferees] = useState<{
    id: string
    name: string
    email: string
    phone: string
    relationship: string
    organization: string
    status: string
    response1: string
    response2: string
    response3: string
    response4: string
    additionalComments: string
  }[]>([])
  const [responses, setResponses] = useState<Responses>({
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    additionalComments: '',
  })
  const [status, setStatus] = useState<string>()
  const [reviewModal, setReviewModal] = useState(false)

  const [allCandidates, setAllCandidates] = useState<any[]>([])
  const [allCandidatesDropdownData, setAllCandidatesDropdownData] = useState<any[]>([])
  const [selectedId, setSelectedId] = useState<string>('')
  const [selectedCandidate, setSelectedCandidate] = useState<any>('')
  const [searchSelectedCandidate, setSearchSelectedCandidate] =
    useState<any>('')
  const [resume, setResume] = useState<UploadedFile | null>(null)
  const [coverLetter, setCoverLetter] = useState<UploadedFile | null>(null)
  const [modal, setModal] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [loadMore, setLoadMore] = useState(false)
  const [reload, setReload] = useState(false)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [responseModal, setResponseModal] = useState<boolean>(false)
  const [responseModalMessage, setResponseModalMessage] = useState<string>('')
  const [error, setError] = useState('')
  const [errorBanner, setErrorBanner] = useState(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate('/signIn')
    })

  }, [navigate])

  function getSortedResult(array: any[]) {
    return array.sort((a: any, b: any) => {
      return a?.name?.localeCompare(b.name)
    })
  }

  const masterDataLoad = async () => {
    try {
      const md = await getMasterData()
      return md
        .filter((a: any) => a?.category?.toString()?.endsWith('rank'))
        .map((md: any) => {
          return {
            ...md,
            qualification: md.category.split('_')[0],
          }
        })
    } catch (error: any) {
      setErrorBanner(true)
      setError(error.message)
    }
  }
  const loadCandidateData = (id: string) => {
    setReferees([])
    loadingService
      .await(getCandidateDetails(id))
      .then(async (details: any | null) => {
        if (details === null) return
        setStatus(details.status)
        loadingService
          .await(getRefereeComments(id))
          .then((res) => {
            if (res?.length > 0) {
              setReferees(res)
            }
          })
          .catch((error) => {
            setErrorBanner(true)
            setError(error.message)
          })
        const rank = await masterDataLoad()
        const md = await getMasterData()
        details.qualifications.map((q: any) => {
          q.sortValue =
            rank?.filter((fd: any) => fd.qualification === q.qualification)[0]
              ?.value ?? 0
        })
        details.qualifications = details.qualifications.sort(
          (a: any, b: any) => b.sortValue - a.sortValue,
        )
        details.certifications.map((q: any) => {
          q.certification =
            md
              .filter(
                (fd: any) => fd.category === 'licences_and_certifications',
              )
              .filter((fd: any) => fd.id === q.certification)[0]?.value ??
            q.certification
        })
        setSelectedCandidate(details)
        setActive(true)
        setSelectedId(id)
        setReload(false)
      })
      .catch((error) => {
        setErrorBanner(true)
        setError(error.message)
      })
  }

  const handleSelectCandidate = async (candidate: any) => {
    if (candidate.id) {
      setActive(false)
      setSelectedCandidate(candidate)
      setSelectedId(candidate.id)
      if (candidate.id) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setReload(true)
        setResume(null)
        setCoverLetter(null)
        loadCandidateData(candidate.id)
      }
    }
  }

  const onSubmit = async () => {
    setOnUpdating(true)
    if (status) {
      const res = await updateStatus(selectedCandidate.id, status?.toString())
      if (res) {
        loadCandidateData(selectedCandidate.id)
        setResponseModal(true)
        setResponseModalMessage(
          status === CandidateStatus.Active
            ? 'This candidate has been re-activated.'
            : 'This candidate has been made inactive.',
        )
      } else {
        setErrorBanner(true)
        setError('Something went wrong! Please try again.')
      }
    }
    setOnUpdating(false)
  }

  /**
   * Loads all data for the candidates page.
   * @returns void
   */
  const loadAllData = () => {
    loadingService
      .await(getAllCandidatesDropdownDataList())
      .then((data) => setAllCandidatesDropdownData(getSortedResult(data)))
      .catch((error) => {
        setErrorBanner(true)
        setError(error.message)
      })
    const params = {
      search:
        searchSelectedCandidate.value !== ''
          ? searchSelectedCandidate.value
          : null,
      sortBy: true,
      page: pageNumber,
    }

    if (pageNumber === 0) {
      setAllCandidates([])
    }
    loadingService
      .await(
        getAllCandidatesList(params).then((res) => {
          setResume(null)
          setCoverLetter(null)

          if (res?.length < 10) {
            setLoadMore(true)
          }
          if (searchSelectedCandidate && searchSelectedCandidate.value !== '') {
            setAllCandidates(res)
          } else {
            setAllCandidates((prevItems) =>
              [...prevItems, ...res]
                .filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id),
                )
                .sort((a: any, b: any) => b.created_at - a.created_at),
            )
            setResume(null)
            setCoverLetter(null)
          }
          if (res?.length === 1) {
            loadCandidateData(res[0]?.id)
            setSelectedCandidate(res[0]?.id)
          } else {
            if (pageNumber === 0) {
              loadCandidateData(res[0]?.id)
              setSelectedCandidate(res[0]?.id)
            } else {
              loadCandidateData(allCandidates[0]?.id)
              setSelectedCandidate(allCandidates[0]?.id)
            }
          }
        }),
      )
      .catch((error) => {
        setErrorBanner(true)
        setError(error.message)
      })
  }

  useEffect(() => {
    if (selectedCandidate) {
      setIsLoading(true)
      if (selectedCandidate.resume_key) {
        const resume_key = selectedCandidate.resume_key?.split('/')
        const resume_url: any = {
          name: resume_key[2],
          contents: '',
          type: 'url',
        }
        setResume(resume_url)
        setIsLoading(false)
      }

      if (selectedCandidate?.cover_letter_key) {
        const cover_letter_key = selectedCandidate.cover_letter_key?.split('/')
        const cover_letter_url: any = {
          name: cover_letter_key[2],
          contents: '',
          type: 'url',
        }
        setCoverLetter(cover_letter_url)
        setIsLoading(false)
      }
      setIsLoading(false)
    }
  }, [selectedCandidate, reload])

  /**
   * Handles the "Load More" button click event.
   * If loadMore is false, it increments the pageNumber state by 1.
   */
  const handleLoadMore = () => {
    if (!loadMore) {
      setPageNumber(pageNumber + 1)
    }
  }

  useEffect(() => {
    loadAllData()
    setReload(false)
    setResume(null)
    setCoverLetter(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingService, pageNumber, searchSelectedCandidate])

  const openResumePDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (selectedCandidate) {
        const params = {
          id: selectedCandidate.id,
          URL_Type: 'resume',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorBanner(true)
      setError(error.message)
    }
  }

  const openCoverLetterPDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (selectedCandidate) {
        const params = {
          id: selectedCandidate.id,
          URL_Type: 'cover_letter',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorBanner(true)
      setError(error.message)
    }
  }

  const handleDeleteCandidate = async (id: string) => {
    setIsLoading(true)
    if (id) {
      loadingService
        .await(deleteCandidate(id))
        .then((res) => {
          if (res) {
            setIsLoading(false)
            setDeleteConfirmationModal(false)
            window.location.reload()
          }
        })
        .catch((err) => {
          setIsLoading(false)
          setErrorBanner(true)
          setError('Something went wrong! Please try again.')
        })
    } else {
      setIsLoading(false)
      setErrorBanner(true)
      setError('Something went wrong! Please try again.')
    }
  }

  /**
   * Used to check if the user is a global admin, and if so, enable status update
   */
  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role === UserRole.GlobalAdmin) {
          setIsAdmin(true)
        }
      })
      .catch(() => {
        setErrorBanner(true)
        setError('Error getting user role')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        {selectedCandidate && (
          <div className={'w-100'}>
            <TopNavigation tabValue={'2'} />
            <div className={styles.content}>
              <div className={styles.leftSide}>
                <CandidateFilter
                  handleSelectCandidate={handleSelectCandidate}
                  isLoading={isLoading}
                  allCandidates={allCandidates}
                  allCandidatesDropdownData={allCandidatesDropdownData}
                  selectedId={selectedId}
                  loadMore={handleLoadMore}
                  setLoadMore={setLoadMore}
                  resetPage={() => setPageNumber(0)}
                  handleSearchCandidateValue={setSearchSelectedCandidate}
                />
              </div>
              <div className={`mt-2 mt-md-0 ${styles.rightSideCandidates}`}>
                <div className={styles.responsive}>
                  <div className={'d-flex'}>
                    <img
                      className={styles.image}
                      src={
                        selectedCandidate.profile_url
                          ? selectedCandidate.profile_url
                          : AvataImage
                      }
                    />
                    <div className={'d-flex flex-column ms-3 '}>
                      <h5 className={'gray-color-text'}>
                        {selectedCandidate && selectedCandidate.name}
                      </h5>
                      <div className={'d-flex align-items-center mt-3'}>
                        <div className={'d-flex me-4'}>
                          <LocationIcon />
                          <h6 className={'ms-3 gray-color-text'}>
                            {selectedCandidate && selectedCandidate.location}
                          </h6>
                        </div>
                      </div>
                      <div className={'d-flex align-items-center mt-3'}>
                        <div className={'d-flex me-4'}>
                          <EmailIcon />
                          <h6 className={'ms-3 gray-color-text'}>
                            {selectedCandidate && selectedCandidate.email}
                          </h6>
                        </div>
                      </div>
                      <div className={'d-flex align-items-center mt-3'}>
                        <div className={'d-flex me-4'}>
                          <PhoneIcon />
                          <h6 className={'ms-3 gray-color-text'}>
                            {selectedCandidate && selectedCandidate.mobile_number}
                          </h6>
                        </div>
                      </div>
                      <div className={'d-flex mt-3'}>
                        <BagIcon />
                        <h6 className={'ms-3 gray-color-text text-normal'}>
                          Availability -{' '}
                          <span className={'text-bold'}>
                            {selectedCandidate &&
                            selectedCandidate.notice_period_days > 0
                              ? `${Math.ceil(
                                selectedCandidate.notice_period_days / 7,
                              )} week${
                                Math.ceil(
                                  selectedCandidate.notice_period_days / 7,
                                ) > 1
                                  ? 's'
                                  : ''
                              }`
                              : 'Immediate'}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div>
                    {selectedCandidate?.summary?.overview &&
                    selectedCandidate.qualifications &&
                    selectedCandidate?.summary?.key_skills_summary &&
                    selectedCandidate.system_expertise ? (
                      <>
                        {selectedCandidate?.summary?.overview.length > 1020 ? (
                          <>
                            <CustomButton
                              onClick={() => {
                                setModal(true)
                              }}
                              className={`mt-2 ${styles.borderBtn} ${styles.linkButton} d-flex justify-content-center w-100 mb-2`}
                              text={'Generate BBB Profile'}
                            />
                          </>
                        ) : (
                          <div
                            className={
                              'd-flex justify-content-center w-100 mb-2 '
                            }
                          >
                            <BlobProvider
                              document={
                                <JPassInvoice
                                  name={selectedCandidate.name}
                                  overView={
                                    selectedCandidate?.summary?.overview
                                  }
                                  EducationExperience={
                                    selectedCandidate.qualifications
                                  }
                                  skillSummary={
                                    selectedCandidate?.summary
                                      ?.key_skills_summary
                                  }
                                  systemsExperience={
                                    selectedCandidate.system_expertise
                                  }
                                  profile_url={selectedCandidate.profile_url}
                                />
                              }
                            >
                              {({ url }) =>
                                url ? (
                                  <a
                                    download={`${selectedCandidate.name} BBB Profile`}
                                    href={url || ''}
                                    target='_blank'
                                    rel='noreferrer'
                                    className={`mt-2 mt-md-0 ${styles.borderBtn} ${styles.linkButton} ${styles.pdfButton}`}
                                  >
                                    Generate BBB Profile
                                  </a>
                                ) : (
                                  <CustomButton
                                    className={`mt-2 ${styles.borderBtn} ${styles.linkButton} d-flex justify-content-center w-100 mb-2`}
                                    disabled={true}
                                    text={'Generate BBB Profile'}
                                  />
                                )
                              }
                            </BlobProvider>
                          </div>
                        )}
                      </>
                      ) : (
                      <CustomButton
                        className={`mt-2 ${styles.borderBtn} ${styles.linkButton} d-flex justify-content-center w-100 mb-2`}
                        disabled={true}
                        text={'Generate BBB Profile'}
                      />
                      )}
                    {isAdmin && (
                      <>
                        <div className={styles.statusUpdate}>
                          <p className={'gray-color-text'}>Update Status</p>
                          <div className={styles.updateDropdown}>
                            <CustomDropdown
                              dataList={[
                                { text: 'Active', value: 'active' },
                                { text: 'Inactive', value: 'inactive' },
                              ]}
                              selectedValue={{
                                text: status
                                  ? status.charAt(0).toUpperCase() +
                                  status.slice(1)
                                  : '',
                                value: status ?? '',
                              }}
                              getSelectedItem={(item: any) =>
                                setStatus(item.value)
                              }
                            />
                          </div>
                          <CustomButton
                            className={`mt-2 ${styles.borderBtn}`}
                            disabled={onUpdating}
                            loading={onUpdating}
                            onClick={() => onSubmit()}
                            text={'Update'}
                          />
                        </div>
                        <div>
                          <CustomButton
                            variant='danger'
                            className='mt-4'
                            text={'Delete Candidate'}
                            onClick={() => setDeleteConfirmationModal(true)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <Preferences candidateId={selectedCandidate.id} />
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <Overview
                    onUpdateOverview={() =>
                      loadCandidateData(selectedCandidate?.id)
                    }
                    disabled={isDisabled}
                    candidateId={selectedCandidate && selectedCandidate?.id}
                    overview={
                      active ? selectedCandidate?.summary?.overview : ''
                    }
                    lastUpdatedAt={
                      active
                        ? selectedCandidate?.summary?.overview_last_updated_at
                        : ''
                    }
                    lastUpdatedBy={
                      active
                        ? selectedCandidate?.summary?.overview_last_updated_by
                        : ''
                    }
                  />
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <KeySkill
                    reloadData={() => loadCandidateData(selectedCandidate?.id)}
                    candidateId={selectedCandidate && selectedCandidate?.id}
                    keySkillsSummary={
                      active
                        ? selectedCandidate?.summary?.key_skills_summary
                        : ''
                    }
                    lastUpdatedAt={
                      active
                        ? selectedCandidate?.summary
                          ?.key_skills_summary_last_updated_at
                        : ''
                    }
                    lastUpdatedBy={
                      active
                        ? selectedCandidate?.summary
                          ?.key_skills_summary_last_updated_by
                        : ''
                    }
                  />
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <Expertise
                    industryExpertise={
                      selectedCandidate && selectedCandidate.industry_expertise
                    }
                    systemExpertise={
                      selectedCandidate && selectedCandidate.system_expertise
                    }
                    taskExpertise={
                      selectedCandidate && selectedCandidate.task_expertise
                    }
                  />
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <div className={`pb-5 ${styles.borderBottom}`}>
                    <Education
                      qualifications={
                        selectedCandidate && selectedCandidate.qualifications
                      }
                    />
                  </div>
                  <div className={'mt-5'}>
                    <Certificate
                      certifications={
                        selectedCandidate && selectedCandidate.certifications
                      }
                    />
                  </div>
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  {resume && (
                    <div>
                      <div
                        className={`d-flex align-items-center justify-content-between ${styles.borderBottomTitle}`}
                      >
                        <div className={'d-flex align-items-center mb-3'}>
                          <ResumeIcon color={colors.blackColor} />
                          <h4 className={'text-almostBlack ms-3 text-bolder'}>
                            Resume
                          </h4>
                        </div>
                      </div>
                      <div className={fileStyles.selectedResume}>
                        <h6 className={'ms-3 text-bold gray-color-text'}>
                          Selected File
                        </h6>

                        <div className={`d-flex ${fileStyles.borderTop}`}>
                          <div
                            className={
                              'd-flex justify-content-between align-items-center w-100'
                            }
                          >
                            <h6
                              className={
                                'text-normal gray-color-text d-flex align-items-center w-100'
                              }
                            >
                              <span className={resumeStyles.ellipseResume}>
                                {resume ? resume.name : ''}
                              </span>{' '}
                              <span
                                hidden={isLoading}
                                className={`${resumeStyles.linkCandidate} ${styles.w15}`}
                                onClick={openResumePDFInNewTab}
                              >
                                View File
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {coverLetter && (
                    <div className={'mt-3'}>
                      <div
                        className={`d-flex align-items-center justify-content-between ${styles.borderBottomTitle}`}
                      >
                        <div className={'d-flex align-items-center mb-3'}>
                          <ResumeIcon color={colors.blackColor} />
                          <h4 className={'text-almostBlack ms-3 text-bolder'}>
                            Cover Letter
                          </h4>
                        </div>
                      </div>
                      <div className={fileStyles.selectedResume}>
                        <h6 className={'ms-3 text-bold gray-color-text'}>
                          Selected File
                        </h6>

                        <div className={`d-flex ${fileStyles.borderTop}`}>
                          <div
                            className={
                              'd-flex justify-content-between align-items-center w-100'
                            }
                          >
                            <h6
                              className={
                                'text-normal gray-color-text d-flex align-items-center w-100'
                              }
                            >
                              <span className={resumeStyles.ellipseResume}>
                                {coverLetter ? coverLetter.name : ''}
                              </span>{' '}
                              <span
                                hidden={isLoading}
                                className={`${resumeStyles.linkCandidate}`}
                                onClick={openCoverLetterPDFInNewTab}
                              >
                                View File
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <WorkExperience
                    data={selectedCandidate?.work_experience}
                    disabled
                  />
                </div>
                <div className={`${styles.contentBox}`}>
                  <div>
                    <div
                      className={`d-flex align-items-center justify-content-between ${styles.borderBottomTitle}`}
                    >
                      <div className={'d-flex align-items-center'}>
                        <QuoteIcon color='black' size={25} />
                        <h4 className={'text-almostBlack ms-3 text-bolder'}>
                          Reviews
                        </h4>
                      </div>
                    </div>
                    {referees?.length == 0 && (
                      <h5 className={'mt-4 gray-color-text'}>
                        Referees have not been added
                      </h5>
                    )}
                    {referees.length > 0 &&
                      referees.map((referee, index) => (
                        <>
                          {referee.status === 'posted_comment' ? (
                            <div
                              className={styles.educationCard}
                              key={`referee-comment-item-${index}`}
                            >
                              <div
                                className={'d-flex align-items-center w-100'}
                              >
                                <div className={'ms-4 w-100'}>
                                  <h6 className={'text-bold gray-color-text'}>
                                    {referee.name}
                                  </h6>
                                  <div className={'d-flex mt-2'}>
                                    <h6
                                      className={
                                        'mt-3 text-normal gray-color-text d-flex w-100'
                                      }
                                    >
                                      <span
                                        className={styles.elipse}
                                        style={{ width: '20%' }}
                                      >
                                        {referee.response1}
                                      </span>
                                      <span
                                        className={
                                          'pointer text-bold gray-color-text ms-2'
                                        }
                                        onClick={() => {
                                          setResponses({
                                            response1: referee.response1,
                                            response2: referee.response2,
                                            response3: referee.response3,
                                            response4: referee.response4,
                                            additionalComments:
                                            referee.additionalComments,
                                          })
                                          setReviewModal(true)
                                        }}
                                      >
                                        Read More
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={styles.educationCard}
                              key={`referee-comment-item-${index}`}
                            >
                              <div className={'d-flex align-items-center'}>
                                <div className={'ms-4'}>
                                  <h6 className={'text-bold gray-color-text'}>
                                    {referee.name}
                                  </h6>
                                  <div className={'d-flex mt-2'}>
                                    <h6
                                      className={
                                        'mt-3 text-normal gray-color-text'
                                      }
                                    >
                                      Review Pending
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
                <div className={`mt-3 ${styles.contentBox}`}>
                  <Assessment
                    hardSkillCategoryResults={selectedCandidate}
                    hardSkillPercentage={Math.floor(
                      (100 *
                        (selectedCandidate?.assessment_results?.last_score ??
                          0)) /
                      (selectedCandidate?.assessment_results?.max_score ?? 1),
                    )}
                    softSkillCategoryResults={[]}
                    candidate={selectedCandidate?.id ?? ''}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {reviewModal && (
          <CustomModal
            open={reviewModal}
            onCloseModal={(val: any) => setReviewModal(val)}
          >
            <ReviewModal
              response1={responses.response1}
              response2={responses.response2}
              response3={responses.response3}
              response4={responses.response4}
              additionalComments={responses.additionalComments}
            />
          </CustomModal>
        )}

        {modal && (
          <CustomModal
            open={modal}
            onCloseModal={(val: any) => {
              setModal(val)
            }}
            title={'Character limit exceeded'}
          >
            <div className={'d-flex align-items-center'}>
              <h5 className={'text-black ms-3 mb-5'}>
                The overview has exceeded the maximum character limit 1010.
                Please edit it in order to generate the BBB profile
              </h5>
            </div>
            <div className={'d-flex align-items-center justify-content-end'}>
              <CustomButton
                className={`ms-2 ${styles.Okbtn}`}
                text={'OK'}
                onClick={() => setModal(false)}
              />
            </div>
          </CustomModal>
        )}
        <ConfirmBannerModal
          onYesClick={() => handleDeleteCandidate(selectedCandidate?.id)}
          open={deleteConfirmationModal}
          onClose={(close) => {
            setDeleteConfirmationModal(close)
          }}
          title={'Delete Confirmation'}
          confirmMessage={'Are you sure you want to delete this candidate?'}
        />
        <ErrorBannerModal
          open={errorBanner}
          onClose={() => setErrorBanner(false)}
          key={`error-modal-candidate-${selectedCandidate?.id}`}
          errorMessage={error}
        />
        <SuccessBannerModal
          onClose={() => setResponseModal(false)}
          open={responseModal}
          key={`success-modal-candidate-${selectedCandidate?.id}`}
          Message={responseModalMessage}
        />
      </div>
    </>
  )
}

export default Candidates
