/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  'aws_project_region': 'ap-southeast-2',
  'aws_cognito_region': 'ap-southeast-2',
  'aws_user_pools_id': 'ap-southeast-2_lCxg6Jotq',
  'aws_user_pools_web_client_id': '78sr8rploj94c58ka4k9uskjt2',
  'oauth': {
    'domain': 'finmatch-prod-user-pool.auth.ap-southeast-2.amazoncognito.com',
    'scope': [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile',
    ],
    'redirectSignIn': 'https://bfinmatched.com.au',
    'redirectSignOut': 'https://bfinmatched.com.au',
    'responseType': 'code',
  },
  'federationTarget': 'COGNITO_USER_POOLS',
  'aws_cognito_username_attributes': [
    'EMAIL',
  ],
  'aws_cognito_social_providers': [],
  'aws_cognito_signup_attributes': [
    'NAME',
    'EMAIL',
  ],
  'aws_cognito_mfa_configuration': 'OFF',
  'aws_cognito_mfa_types': [],
  'aws_cognito_password_protection_settings': {
    'passwordPolicyMinLength': 8,
    'passwordPolicyCharacters': [],
  },
  'aws_cognito_verification_mechanisms': [
    'EMAIL',
  ],
}


export default awsmobile
