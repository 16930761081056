import { RequestStatus } from '../enums/commonApplication.enum'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import { axiosInstance } from './base.service'

export type GetQuestionsResponse = {
  status: RequestStatus;
  result: Question[] | null;
};
export type Answer = {
  id: string;
  text: string;
  value: number;
  lower_bound: number;
  upper_bound: number;
};

export enum QuestionType {
  FinancialsValue = 'formula-value',
  RewardPenalty = 'reward-penalty',
  WrittenResponse = 'written-response',
}

export type Question = {
  id: string;
  text: string;
  type: QuestionType;
  task: any;
  property: string;
  answers: Answer[];
  response?: string;
  answer?: Answer;
};

/**
 * Activate or deactivate an organization
 * Inactive organizations cannot post jobs
 * sends an email to the managing user of the organization about the status
 * @param params Contains the organization id and the active status
 * @returns The updated organization
 */
/**
 * Activate or deactivate an organization
 * Inactive organizations cannot post jobs
 * sends an email to the managing user of the organization about the status
 * @param params Contains the organization id and the active status
 * @returns The updated organization
 */

export const getQuestionsForOrg = async (params: { id: string }): Promise<any> => {
  try {
    const res = await axiosInstance.get<BaseResponse<boolean>>(`/needsanalysis/get/org?id=${params.id}`)
    return res.data
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}
