import React from 'react'

interface Props {
  size?: number
  color?: string
}

const CalenderIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 16}
      height={size ?? 18}
      fill='none'
      viewBox={'0 0 16 18'}
    >
      <path
        d='M11.333 1.5v3.333M4.666 1.5v3.333M1.333 8.167h13.333m-7.5 3.333H8V14M3 3.167h10c.92 0 1.666.746 1.666 1.666v10c0 .92-.746 1.667-1.666 1.667H3c-.92 0-1.667-.746-1.667-1.667v-10c0-.92.746-1.666 1.667-1.666Z'
        stroke={color ?? '#636363'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CalenderIcon
