import { RequestStatus } from '../enums/commonApplication.enum'
import { axiosInstance } from './base.service'
import { InterviewQuestionAnswers, InterviewQuestions } from '../interfaces/interview.interface'

export const getInterviewQuestions = async (): Promise<InterviewQuestions[]> => {
  const url: string = `/interview-questions/getAll`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: InterviewQuestions[]
    }>(url)
    return res.data.result

  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}
export const answerInterviewQuestion = async (
  question: InterviewQuestionAnswers[],
): Promise<boolean> => {
  const url: string = `/interview-questions/submit_answer`

  try {
    const res = await axiosInstance.post<{
      status: string
      result: InterviewQuestionAnswers[]
    }>(url, question)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

// Get answers for questions per candidate
export const getAnswersForQuestions = async (
  params: { hiringProcessId: string; candidateId: string },
): Promise<any[]> => {
  const url: string = `/interview-questions/get_question_answer?${new URLSearchParams(
    {
      hiringProcessId: params.hiringProcessId,
      candidateId: params.candidateId,
    },
  )}`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: any[]
    }>(url)
    return res.data.result

  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}
