import React from 'react'

interface Props {
  size?: number
  color?: string
}

const SearchIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 20}
      height={size ?? 20}
      viewBox={'0 0 20 20'}
      fill='none'
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m19 19-6-6m2-5A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
      />
    </svg>
  )
}

export default SearchIcon
