import React from 'react'

interface Props {
  size?: number
  color?: string
}

const BuildingIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 20}
      height={size ?? 20}
      fill='none'
      viewBox={'0 0 20 20'}
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M1 19h18M7 6h1m-1 4h1m-1 4h1m4-8h1m-1 4h1m-1 4h1M3 19V3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16'
      />
    </svg>
  )
}

export default BuildingIcon
