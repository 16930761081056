import React, { useEffect, useState } from 'react'
import styles from '../../pages/teams/styles.module.scss'
import LoadingSpinner from '../components/customLoadingSpinner/loadingSpinner'
import defaultProfileImage from '../../images/avatar.png'

interface ProfileImageProps {
  src: string | undefined
  alt: string
}

function ProfileImage({ src, alt }: ProfileImageProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (!src) {
      setIsImageLoaded(true)
      setIsError(true)
      return
    }

    const img = new Image()
    img.onload = () => {
      setIsImageLoaded(true)
    }
    img.onerror = () => {
      setIsImageLoaded(true)
      setIsError(true)
    }
    img.src = src
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return (
    <div className='align-self-stretch me-5'>
      {isError ? (
        <img
          src={defaultProfileImage}
          alt={defaultProfileImage}
          className={styles.profilePic}
        />
      ) : isImageLoaded ? (
        <img src={src} alt={alt} className={styles.profilePic} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}

export default ProfileImage
