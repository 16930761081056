import React from 'react'

interface Props {
  size?: number
  color?: string
}

const QuoteIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 24}
      height={size ?? 24}
      fill='none'
      viewBox={'0 0 24 24'}
    >
      <path
        stroke={color ?? '#1F1F1F'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M10 11H6a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v6c0 2.667-1.333 4.333-4 5m13-7h-4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v6c0 2.667-1.333 4.333-4 5'
      />
    </svg>
  )
}

export default QuoteIcon
