import React, { useMemo, useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import styles from './sinIn.module.scss'
import * as Yup from 'yup'


import SuccessIcon from '../../icons/success.icon'

import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'

import CustomButton from '../../shared/components/customButton/customButton'
import CustomInputField from '../../shared/components/customInputField/customInputField'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

type ResetPasswordProps = {
  newPassword: string
  confirmPassword: string
}


export interface resetPasswordPros {
  user: any
}

function PasswordRest(user: resetPasswordPros) {
  const [isLoading, setIsLoading] = useState(false)
  const navigation = useNavigate()

  const initialVal: ResetPasswordProps = useMemo(() => {
    return {
      newPassword: '',
      confirmPassword: '',
    }
  }, [])

  const [type, setType] = useState<'EnterEmail' | 'NewPassword' | 'Success' | 'Failed'>('EnterEmail')


  const handleSubmit = async (
    values: FormikValues,
  ) => {
    try {
      setIsLoading(true)
      await Auth.completeNewPassword(
        user.user,
        values.confirmPassword, // the new password
      )
      setIsLoading(false)

      navigation('/dashboard')
    } catch (error) {
      setIsLoading(false)
      setType('Failed')
    }
  }

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{9,}$/,
        'Password must contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols',
      )
      .required('Please enter your password')
    ,
    confirmPassword: Yup.string()
      .notOneOf(
        [Yup.ref('currentPassword')],
        'Confirm password cannot be the same as the current password',
      )
      .required('Please confirm your password')
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match.'),
  })


  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik
          initialValues={initialVal}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange
        >
          {({ setFieldValue }) => (
            <Form className={styles.modal}>
              <div className={styles.w75}>
                <>
                  <h3>New Password</h3>
                  <div className={'mt-4'}>
                    <CustomInputField
                      type={'password'}
                      name={'newPassword'}
                      placeholder={'Enter New Password'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('newPassword', e.target.value)
                      }
                      disabled={isLoading}
                    />
                    <div className={`mt-2 ${loadingStyles.borderClass}`}>
                      <CustomInputField
                        placeholder={'Confirm Password'}
                        name={'confirmPassword'}
                        type={'password'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('confirmPassword', e.target.value)
                        }
                        disabled={isLoading}
                      />
                    </div>
                    <div className={'mt-4 d-flex justify-content-end align-items-center'}>
                      <CustomButton
                        type={'submit'}
                        text={'Submit'}
                        className={styles.widthBtn}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </>
                {type === 'Success' && (
                  <>
                    <h3>Success</h3>
                    <div className={'d-flex align-items-center'}>
                      <div>
                        <SuccessIcon />
                      </div>
                      <h4 className={'text-success ms-3 mt-3'}>
                        Your password Changed.
                      </h4>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default PasswordRest
