import React from 'react'

interface Props {
  size?: number
  color?: string
}

const DollarIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 12}
      height={size ?? 20}
      fill='none'
      viewBox={'0 0 12 20'}
    >
      <path
        d='M10.7 6A3 3 0 0 0 8 4H4a3 3 0 1 0 0 6h4a3 3 0 0 1 0 6H4a3 3 0 0 1-2.7-2M6 1v3m0 12v3'
        stroke={color ?? '#374151'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DollarIcon
