import React from 'react'

interface Props {
  size?: number
  color?: string
}

const CounterOfferIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 18}
      height={size ?? 15}
      fill='none'
      viewBox={'0 0 18 15'}
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m5.667 9.5-4-4m0 0 4-4m-4 4h11a4 4 0 1 1 0 8h-1'
      />
    </svg>
  )
}

export default CounterOfferIcon
