import { axiosInstance } from './base.service'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import FileService from './file.service'
import { TeamMember } from '../interfaces/teams.interface'
import { HiringTeamAddRequest } from '../interfaces/hiring.interface'
import { RequestStatus } from '../enums/commonApplication.enum'

export default class TeamsService {
  constructor(private readonly fileService: FileService = new FileService()) {
  }

  public async addTeamMember(form: any): Promise<boolean | null> {
    const url: string = `/user/create-cognito-account`
    try {
      const fromData = new FormData()
      fromData.append('file', form?.file?.original)
      fromData.append('email', form.email)
      fromData.append('name', form.name)
      fromData.append('contactNumber', form.phone)
      fromData.append('position', form.position)
      fromData.append('role', form.role)
      fromData.append('team', form.team)
      const res = await axiosInstance.post<BaseResponse<boolean>>(
        url,
        fromData,
      )
      return res.status === 201
    } catch (e) {

      return false
    }
  }

  // inactivate team member
  public async inactivateTeamMember(id: string): Promise<boolean | null> {
    const url: string = `/user/inactivate-team-member?id=${id}`
    try {
      const res = await axiosInstance.post<BaseResponse<boolean>>(url)
      return res.status === 201
    } catch (e) {
      return false
    }

  }
}

export const getTeamMembers = async (status?: string): Promise<TeamMember[]> => {
  const queryParams: Record<string, string> = {}

  // Add the 'status' parameter to the queryParams object if it's provided
  if (status) {
    queryParams.status = status
  }

  // Construct the query string from the queryParams object
  const queryString = new URLSearchParams(queryParams).toString()

  // Construct the URL with the query string
  const url: string = `/user/get-team-members${queryString ? `?${queryString}` : ''}`

  try {
    const res = await axiosInstance.get<BaseResponse<TeamMember[]>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}

export const updateTeamMember = async (form: any): Promise<boolean> => {
  const url: string = `/user/update-team-member`
  try {
    const formData = new FormData()
    formData.append('file', form?.file?.original)
    formData.append('id', form.id)
    formData.append('email', form.email)
    formData.append('name', form.name)
    formData.append('role', form.role)
    formData.append('phone', form.phone)
    formData.append('position', form.position)
    formData.append('team', form.team)
    const res = await axiosInstance.post<BaseResponse<boolean>>(url, formData)

    return res.status === 201
  } catch (e) {
    return false
  }
}

export const getHiringTeamMembers = async (id: string): Promise<any[]> => {
  const url: string = `/hiring/hiring-team`
  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url, {
      params: {
        id: id,
      },
    })

    if (res.status === 200) {
      return res.data.result // Extract the result property from BaseResponse
    } else {
      throw new Error('No response')
    }
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}

export const addHiringTeamMembers = async (
  details: HiringTeamAddRequest,
): Promise<boolean> => {
  const url: string = `/hiring/add-hiring-team`
  try {
    const res = await axiosInstance.post<BaseResponse<boolean>>(url, details)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }

}
