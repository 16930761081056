import React from 'react'

interface Props {
  size?: number
  color?: string
}

const ApproveIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 18}
      height={size ?? 18}
      fill='none'
    >
      <path
        stroke={color ?? '#102A2D'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m6 8 3 3 8-8m0 6v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h9'
      />
    </svg>
  )
}

export default ApproveIcon
