import React from 'react'

interface Props {
  size?: number
  color?: string
}

const UpArrowIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 12}
      height={size ? size - 5 : 7}
      fill='none'
      viewBox={'0 0 12 7'}
    >
      <path
        d='M11 6 6 1 1 6'
        stroke={color ?? '#A6A6A6'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UpArrowIcon
