import React from 'react'

interface Props {
  size?: number
  color?: string
}

const StarIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 42}
      height={size ? size - 3 : 39}
      fill='none'
      viewBox={'0 0 42 39'}
    >
      <path
        d='M21 31.042 9.685 36.99l2.161-12.6-9.166-8.923 12.65-1.834L20.987 2.17l5.658 11.464 12.65 1.834-9.167 8.922 2.162 12.6L21 31.043Z'
        stroke={color ?? '#F98F12'}
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default StarIcon
