import React from 'react'

interface Props {
  size?: number
  color?: string
}

const ThumbsUpIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 20}
      height={size ?? 19}
      fill='none'
      viewBox={'0 0 20 19'}
    >
      <path
        stroke={color ?? '#00732E'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M5.333 8.5v8a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h3Zm0 0a4 4 0 0 0 4-4v-1a2 2 0 1 1 4 0v5h3a2 2 0 0 1 2 2l-1 5c-.144.613-.417 1.14-.777 1.501-.361.36-.79.536-1.223.499h-7a3 3 0 0 1-3-3'
      />
    </svg>
  )
}

export default ThumbsUpIcon
