import axios from 'axios'
import { Auth } from 'aws-amplify'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

axiosInstance.interceptors.request.use(
  async function (config) {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken().getJwtToken()
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)
