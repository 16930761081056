import React from 'react'

interface Props {
  size?: number
  color?: string
}

const FilterIconNew: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 19}
      height={size ? size - 5 : 14}
      viewBox={'0 0 19 14'}
      fill='none'
    >
      <path
        stroke={color ?? '#1A3665'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M1 1h9M1 7h7m-7 6h7m4-3 3 3m0 0 3-3m-3 3V1'
      />
    </svg>
  )
}

export default FilterIconNew
