import React from 'react'

interface Props {
  size?: number
  color?: string
}

const SettingsIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 20}
      height={size ?? 21}
      fill='none'
      viewBox={'0 0 20 21'}
    >
      <path
        stroke={color ?? '#1A3665'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M8.325 2.817c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37 1 .608 2.296.07 2.572-1.065Z'
      />
      <path
        stroke={color ?? '#1A3665'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M10 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
      />
    </svg>
  )
}

export default SettingsIcon
