import { RequestStatus } from '../enums/commonApplication.enum'
import {
  CultureCatchUpQuestion,
  HardSkillsAssessmentQuestion,
  InterviewQuestion,
} from '../interfaces/assessment.interface'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import { axiosInstance } from './base.service'

/**
 * Get all the assessment questions
 * @returns a list of assessment questions
 */
export const getHardSkillsAssessmentQuestions = async (): Promise<HardSkillsAssessmentQuestion[]> => {
  try {
    const res = await axiosInstance.get<BaseResponse<HardSkillsAssessmentQuestion[]>>('/assessment/questions/get')
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Get all the interview assessment questions
 * @returns a list of assessment questions
 */
export const getInterviewAssessmentQuestions = async (): Promise<InterviewQuestion[]> => {
  try {
    const res = await axiosInstance.get<BaseResponse<InterviewQuestion[]>>(
      '/interview-questions/getAll',
    )
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getCultureCatchUpAssessmentQuestions = async (): Promise<CultureCatchUpQuestion[]> => {
  try {
    const res = await axiosInstance.get<BaseResponse<CultureCatchUpQuestion[]>>(
      'candidate/catchup_questions/getAll',
    )
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Adds new questions to the hard skills assessment
 * @param questions The questions to be added
 * @returns True if the questions were added successfully, false otherwise
 */
export const addHardSkillsAssessmentQuestions = async (
  questions: HardSkillsAssessmentQuestion[],
): Promise<boolean> => {
  try {
    const res = await axiosInstance.post<BaseResponse<null>>(
      '/assessment/questions/create',
      questions.map((question) => ({
        text: question.text,
        answers: question.answers.map((answer) => ({
          text: answer.text,
          correct: answer.correct,
        })),
        category: question.category,
        type: question.type,
        points: question.points,
      })),
    )
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

/**
 * Adds new questions to the interview assessment
 * @param questions The questions to be added
 * @returns True if the questions were added successfully, false otherwise
 */
export const addInterviewAssessmentQuestions = async (
  questions: InterviewQuestion[],
): Promise<boolean> => {
  try {
    const res = await axiosInstance.post<BaseResponse<null>>(
      '/interview-questions/new_question',
      questions.map((question) => ({
        text: question.text,
      })),
    )
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

export const addCultureCatchUpAssessmentQuestions = async (
  questions: CultureCatchUpQuestion[],
): Promise<boolean> => {
  try {
    const res = await axiosInstance.post<BaseResponse<null>>(
      'candidate/catchup_questions/new_question',
      questions.map((question) => ({
        text: question.text,
      })),
    )
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

/**
 * Removes questions from the hard skills assessment
 * @param questionIds The ids of the questions to be removed
 * @returns True if the questions were removed successfully, false otherwise
 */
export const removeHardSkillsAssessmentQuestions = async (
  questionIds: string[],
): Promise<boolean> => {
  try {
    const res = await axiosInstance.post<BaseResponse<null>>(
      '/assessment/questions/remove',
      questionIds.map((id) => ({ id })),
    )
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}
export const removeCultureCatchUpAssessmentQuestions = async (
  questionIds: string[],
): Promise<boolean> => {
  try {
    const res = await axiosInstance.post<BaseResponse<null>>(
      'candidate/catchup_questions/remove',
      questionIds.map((id) => ({ id })),
    )
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

/**
 * Removes questions from the interview assessment
 * @param questionIds The ids of the questions to be removed
 * @returns True if the questions were removed successfully, false otherwise
 */
export const removeInterviewAssessmentQuestions = async (
  questionIds: string[],
): Promise<boolean> => {
  try {
    const res = await axiosInstance.post<BaseResponse<null>>(
      '/interview-questions/remove_question',
      questionIds.map((id) => ({ id })),
    )
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}
