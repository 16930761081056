import { RequestStatus } from '../enums/commonApplication.enum'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import { AddMasterDataEntry, MasterDataEntry } from '../interfaces/masterData.interface'
import { axiosInstance } from './base.service'

/**
 * Get all master data
 * @returns a list of all master data
 */
export const getMasterData = async (): Promise<MasterDataEntry[]> => {
  try {
    const res = await axiosInstance.get<BaseResponse<MasterDataEntry[]>>(
      '/data/getAll',
    )
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}

/**
 * Update a signle master data entry
 * @param data the master data entry to update
 * @returns true if the update was successful, false otherwise
 */
export const updateMasterData = async (data: MasterDataEntry): Promise<any> => {
  try {
    const res = await axiosInstance.get<BaseResponse<boolean>>(
      '/data/update?' +
      new URLSearchParams({
        id: data.id,
        category: data.category,
        value: data.value,
      }),
    )
    return res

  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}

export const addMasterData = async (data: AddMasterDataEntry): Promise<any> => {
  try {
    const res = await axiosInstance.get<BaseResponse<boolean>>(
      '/data/add?' +
      new URLSearchParams({
        category: data.category,
        value: data.value,
      }),
    )
    return res
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}

export const deleteMasterData = async (id: string): Promise<any> => {
  const url: string = `/data/delete_master_data?id=${id}`

  try {
    const res = await axiosInstance.post<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}
