import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styles from './styles.module.scss'
import CustomDropdown from '../customDropdown/customDropdown'
import { Field, Form, Formik } from 'formik'
import CustomButton from '../customButton/customButton'
import RecycleBinIcon from '../../../icons/recycleBinIcon'
import Tag from '../tag/tag'
import LoadingService from '../../services/loading.service'
import { addHiringTeamMembers, getHiringTeamMembers, getTeamMembers } from '../../services/teams.service'
import { TeamMember, UserTypes } from '../../interfaces/teams.interface'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type AccountProfileProp = {
  job_id: string
  onCloseModal: (val: boolean, count: number) => void
}

function AccountProfileModal({ job_id, onCloseModal }: AccountProfileProp) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [adminTeamMembers, setTeamAdminMembers] = useState<TeamMember[]>([])
  const [teamManagerMembers, setTeamManagerMembers] = useState<TeamMember[]>([])
  const [teamInterviewMembers, setTeamInterviewMembers] = useState<TeamMember[]>([])
  const [teamCultureCatchupMembers, setTeamCultureCatchupMembers] = useState<TeamMember[]>([])
  const [selectedAdminUser, setSelectedAdminUser] = useState<any[]>([])
  const [selectedManagerUser, setSelectedManagerUser] = useState<any[]>([])
  const [selectedInterViewUser, setSelectedInterViewUser] = useState<any[]>([])
  const [selectedCultureCatchupUser, setSelectedCultureCatchupUser] = useState<any[]>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [initialVal, setInitialVal] = useState<any>({
    globalAdmin: '',
    teamManager: '',
    interviewUser: [],
    teamCultureCatchup: [],
  })
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )

  const loadHiringTeamData = useCallback(async (userList: any) => {
    try {
      setIsLoading(true)
      const data: any = await getHiringTeamMembers(job_id)
      if (data.length !== 0) {
        let userData
        if (data?.filter((fds: any) => fds.teamtype === 'admin_users')[0].user_id) {
          userData = userList.filter(
            (fd: any) =>
              fd.cognito_id ===
              data?.filter((fds: any) => fds.teamtype === 'admin_users')[0].user_id,
          )[0]
          setSelectedAdminUser([
            {
              name: userData?.full_name,
              id: userData?.cognito_id,
              imageUrl: userData?.profile_image_key,
            },
          ])
        } else {
          userData = userList.filter(
            (fd: any) =>
              fd.cognito_id === process.env.REACT_APP_ADMI_USER,
          )[0]
          setSelectedAdminUser([
            {
              name: userData.full_name,
              id: userData.cognito_id,
              imageUrl: userData.profile_image_key,
            },
          ])
        }

        const userDataT = userList.filter(
          (fd: any) =>
            fd.cognito_id ===
            data?.filter((fds: any) => fds.teamtype === 'team_manager')[0]?.user_id,
        )[0]
        setInitialVal({
          globalAdmin: userData.cognito_id,
          teamManager: userDataT?.cognito_id,
          interviewUser: [],
          teamCultureCatchup: [],
        })
        if (userDataT) {
          setSelectedManagerUser([
            {
              name: userDataT?.full_name,
              id: userDataT?.cognito_id,
              imageUrl: userDataT?.profile_image_key,
            },
          ])
        }

        const interviewArray = data?.filter(
          (fd: any) => fd.teamtype === 'interview',
        )
        const cultureCatchupArray = data?.filter(
          (fd: any) => fd.teamtype === 'culture_catchup',
        )

        const iArray: any[] = []
        const cArray: any[] = []
        for (const userI of interviewArray) {
          const userDataI = userList.filter(
            (fd: any) => fd.cognito_id === userI.user_id,
          )[0]
          iArray.push({
            name: userDataI.full_name,
            id: userDataI.cognito_id,
            imageUrl: userDataI.profile_image_key,
          })
        }
        setSelectedInterViewUser(iArray)

        for (const userC of cultureCatchupArray) {
          const userDataC = userList.filter(
            (fd: any) => fd.cognito_id === userC.user_id,
          )[0]
          cArray.push({
            name: userDataC?.full_name,
            id: userDataC?.cognito_id,
            imageUrl: userDataC?.profile_image_key,
          })
        }
        setSelectedCultureCatchupUser(cArray)

        setIsLoading(false)
      } else {
        let userData
        userData = userList.filter(
          (fd: any) =>
            fd.cognito_id === process.env.REACT_APP_ADMI_USER,
        )[0]
        if (userData) {
          setSelectedAdminUser([
            {
              name: userData?.full_name,
              id: userData?.cognito_id,
              imageUrl: userData?.profile_image_key,
            },
          ])
          setInitialVal({
            globalAdmin: userData?.cognito_id,
            teamManager: [],
            interviewUser: [],
            teamCultureCatchup: [],
          })
        }

      }
      setIsLoading(false)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)

      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])
  const loadMemberData = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = await getTeamMembers()
      if (data) {
        setTeamAdminMembers(
          data.filter((fd) => fd.__role__.role === UserTypes.Admin),
        )
        setTeamManagerMembers(
          data.filter((fd) => fd.__role__.role === UserTypes.Manager),
        )
        setTeamInterviewMembers(
          data,
        )
        setTeamCultureCatchupMembers(
          data.filter((fd) => fd.__role__.role === UserTypes.Staff),
        )
        setIsLoading(false)
        await loadHiringTeamData(data)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    loadMemberData().then()
  }, [loadMemberData, loadingService])

  const handleSubmit = async () => {
    setIsLoading(true)
    const data = {
      job_id: job_id,
      admin_users: selectedAdminUser.map((md) => ({
        user_id: md.id,
      })),
      culture_catchup: selectedCultureCatchupUser.map((md, index) => ({
        user_id: md.id,
        isprimaryuser: index == 0,
      })),
      interview: selectedInterViewUser.map((md, index) => ({
        user_id: md.id,
        isprimaryuser: index == 0,
      })),
      team_manager: selectedManagerUser.map((md) => ({
        user_id: md.id,
      })),
    }
    try {
      const result: any = await addHiringTeamMembers(data)
      if (result) {
        setIsLoading(false)
        onCloseModal(false, (selectedAdminUser.length + selectedCultureCatchupUser.length + selectedInterViewUser.length + selectedManagerUser.length))

      } else {
        onCloseModal(false, (selectedAdminUser.length + selectedCultureCatchupUser.length + selectedInterViewUser.length + selectedManagerUser.length))
        setErrorModel(true)
        setErrorMessage('Error...')
      }
    } catch (error: any) {
      onCloseModal(false, (selectedAdminUser.length + selectedCultureCatchupUser.length + selectedInterViewUser.length + selectedManagerUser.length))
      setIsLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const addAdminUser = (user: string) => {
    if (user) {
      const userData = adminTeamMembers.filter(
        (fd) => fd.cognito_id === user,
      )[0]
      setSelectedAdminUser([
        {
          name: userData.full_name,
          id: userData.cognito_id,
          imageUrl: userData.profile_image_key,
        },
      ])
    }
  }

  const removeAdminUser = (user: string) => {
    const newList = selectedAdminUser.filter((fd) => fd.id !== user)
    setSelectedAdminUser(newList)
  }

  const addManagerUser = (user: string) => {
    if (user) {
      const userData = teamManagerMembers.filter(
        (fd) => fd.cognito_id === user,
      )[0]
      setSelectedManagerUser([
        {
          name: userData.full_name,
          id: userData.cognito_id,
          imageUrl: userData.profile_image_key,
          role: userData.__role__.role,
        },
      ])
    }
  }

  const removeManagerUser = (user: string) => {
    const newList = selectedManagerUser.filter((fd) => fd.id !== user)
    setSelectedManagerUser(newList)
  }

  const addInterViewUser = (user: string) => {
    if (user) {
      const userData = teamInterviewMembers.filter(
        (fd) => fd.cognito_id === user,
      )[0]

      if (
        selectedInterViewUser.length < 2 &&
        selectedInterViewUser.findIndex((fd) => fd.id === user) === -1
      ) {
        setSelectedInterViewUser([
          ...selectedInterViewUser,
          {
            name: userData.full_name,
            id: userData.cognito_id,
            imageUrl: userData.profile_image_key,
          },
        ])
      }
    }
  }

  const removeInterViewUser = (user: string) => {
    const newList = selectedInterViewUser.filter((fd) => fd.id !== user)
    setSelectedInterViewUser(newList)
  }

  const addCultureCatchupViewUser = (user: string) => {
    if (user) {
      const userData = teamCultureCatchupMembers.filter(
        (fd) => fd.cognito_id === user,
      )[0]
      if (
        selectedCultureCatchupUser.length < 2 &&
        selectedCultureCatchupUser.findIndex((fd) => fd.id === user) === -1
      ) {
        setSelectedCultureCatchupUser([
          ...selectedCultureCatchupUser,
          {
            name: userData.full_name,
            id: userData.cognito_id,
            imageUrl: userData.profile_image_key,
          },
        ])
      }
    }
  }

  const removeCultureCatchupUser = (user: string) => {
    const newList = selectedCultureCatchupUser.filter((fd) => fd.id !== user)
    setSelectedCultureCatchupUser(newList)
  }


  const validate = (values: any) => {
    const errors: any = {}
    if (values.globalAdmin === '') {
      errors.globalAdmin = 'Required'
    }

    if (values.teamManager === '') {
      errors.teamManager = 'Required'
    }
    const duplicates = values?.interviewUser?.filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) !== index,
    )

    if (duplicates.length > 0) {
      errors.interviewUser = 'Duplicate options are not allowed'
    }

    const duplicates2 = values?.teamCultureCatchup?.filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) !== index,
    )

    if (duplicates2.length > 0) {
      errors.teamCultureCatchup = 'Duplicate options are not allowed'
    }
    return errors
  }

  return (
    <>
      <Formik
        initialValues={initialVal}
        validate={validate}
        onSubmit={handleSubmit} enableReinitialize={true}
      >
        {({ setFieldValue, errors, validateForm, values }) => (
          <Form className={styles.responsive}>
            {isLoading &&
              <LoadingSpinner />
            }
            <div style={{ opacity: isLoading ? 0.5 : 1 }}>
              <div className={'d-flex justify-content-between'}>
                <div>
                  <h6 className={'gray-color-text'}>Global Admin </h6>
                  <div className={'mt-4'}>
                    {selectedAdminUser?.map((item, index) => (
                      <Tag key={`ga${index}`}
                           name={item.name}
                           id={item.id}
                           imageUrl={item.imageUrl}
                           remove={(id) => {
                             setFieldValue('globalAdmin', '')
                             removeAdminUser(id)
                             validateForm()
                           }}
                      />
                    ))}
                    {errors.globalAdmin && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-10px',
                          marginBottom: '25px',
                          position: 'relative',
                        }}
                      >
                        <>{errors.globalAdmin}</>
                      </div>
                    )}
                    <div className={'mt-4'} style={{ zIndex: 3, position: 'relative' }}>
                      <h6 className={'text-normal'}>Add another</h6>
                      <div className={styles.dropdownWidth}>
                        <CustomDropdown
                          name={'adminTeam'}
                          dataList={adminTeamMembers.map((data) => ({
                            value: data.cognito_id,
                            text: data.full_name,
                          }))}
                          placeHolder={'Name'}
                          selectedValue={{
                            value: '',
                            text: '',
                          }}
                          getSelectedItem={(data: any) => {
                            if (data.value) {
                              addAdminUser(data.value)
                              setFieldValue('globalAdmin', data.value)
                            }

                          }}
                        />
                      </div>
                    </div>
                    <Field type='hidden' name='globalAdmin' value={values.globalAdmin} />
                  </div>
                </div>
                <div className={'ms-5'}>
                  <h6 className={'gray-color-text'}>Manager</h6>
                  <div className={'mt-4'}>
                    {selectedManagerUser.map((item, index) => (
                      <Tag key={`tm${index}`}
                           name={item.name}
                           id={item.id}
                           imageUrl={item.imageUrl}
                           remove={(id) => {
                             removeManagerUser(id)
                             setFieldValue('teamManager', '')
                             validateForm()
                           }}
                      />
                    ))}
                    {errors.teamManager && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-10px',
                          marginBottom: '25px',
                          position: 'relative',
                        }}
                      >
                        <>{errors.teamManager}</>
                      </div>
                    )}
                    <div className={'mt-4'} style={{ zIndex: 3, position: 'relative' }}>
                      <h6 className={'text-normal'}>Add another</h6>
                      <div className={styles.dropdownWidth}>
                        <CustomDropdown
                          selectedValue={{
                            value: '',
                            text: '',
                          }}
                          dataList={teamManagerMembers.map((data) => ({
                            value: data.cognito_id,
                            text: data.full_name,
                          }))}
                          placeHolder={'Name'}
                          getSelectedItem={(data: any) => {
                            if (data.value) {
                              setFieldValue('teamManager', data.value)
                              addManagerUser(data.value)
                            }

                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Field type='hidden' name='teamManager' />
                </div>
              </div>
              <div className={'d-flex justify-content-between mt-5 mb-5'}>
                <div>
                  <h6 className={'gray-color-text'}>Interviewers</h6>
                  <div className={'mt-4'}>
                    {selectedInterViewUser.map((item, index) => (
                      <Tag key={`intu${index}`}
                           showUserType={true}
                           primaryUser={index === 0 ? true : item.isprimaryuser}
                           name={item.name}
                           id={item.id}
                           imageUrl={item.imageUrl}
                           remove={(id) => {
                             removeInterViewUser(id)
                             setFieldValue(
                               'interviewUser',
                               values.interviewUser.filter(
                                 (fd: any) => fd !== id,
                               ),
                             )
                             validateForm()
                           }}
                      />
                    ))}
                    {errors.interviewUser && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-10px',
                          marginBottom: '25px',
                          position: 'relative',
                        }}
                      >
                        <>{errors.interviewUser}</>
                      </div>
                    )}
                    <div className={'mt-4'} style={{ zIndex: 2, position: 'relative' }}>
                      <h6 className={'text-normal'}>Add another</h6>
                      <div className={styles.dropdownWidth}>
                        <CustomDropdown
                          selectedValue={{
                            value: '',
                            text: '',
                          }}
                          dataList={teamInterviewMembers.map((data) => ({
                            value: data.cognito_id,
                            text: data.full_name,
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          }))}
                          placeHolder={'Name'}
                          getSelectedItem={(data: any) => {
                            addInterViewUser(data.value)
                            data.value &&
                            values.interviewUser.findIndex(
                              (fd: any) => fd === data.value,
                            ) === -1
                              ? setFieldValue('interviewUser', [
                                ...values.interviewUser,
                                data.value,
                              ])
                              : null
                            validateForm()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Field type='hidden' name='interviewUser' />
                </div>
                <div className={'ms-5'}>
                  <h6 className={'gray-color-text'}>Team Support</h6>
                  <div className={'mt-4'}>
                    {selectedCultureCatchupUser.map((item, index) => (
                      <Tag key={`ccu${index}`}
                           showUserType={true}
                           primaryUser={index === 0 ? true : item.isprimaryuser}
                           name={item.name}
                           id={item.id}
                           imageUrl={item.imageUrl}
                           remove={(id) => {
                             values.teamCultureCatchup.filter(
                               (fd: any) => fd !== id,
                             )
                             removeCultureCatchupUser(id)
                             setFieldValue(
                               'teamCultureCatchup',
                               values.teamCultureCatchup.filter(
                                 (fd: any) => fd !== id,
                               ),
                             )
                             validateForm()
                           }}
                      />
                    ))}
                    {errors.teamCultureCatchup && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-10px',
                          marginBottom: '25px',
                          position: 'relative',
                        }}
                      >
                        <>{errors.teamCultureCatchup}</>
                      </div>
                    )}
                    <div className={'mt-4'}>
                      <h6 className={'text-normal'}>Add another</h6>
                      <div className={styles.dropdownWidth} style={{ zIndex: 2, position: 'relative' }}>
                        <CustomDropdown
                          selectedValue={{
                            value: '',
                            text: '',
                          }}
                          dataList={teamCultureCatchupMembers.map((data) => ({
                            value: data.cognito_id,
                            text: data.full_name,
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          }))}
                          placeHolder={'Name'}
                          getSelectedItem={(data: any) => {
                            addCultureCatchupViewUser(data.value)
                            data.value &&
                            values.teamCultureCatchup.findIndex(
                              (fd: any) => fd === data.value,
                            ) === -1
                              ? setFieldValue('teamCultureCatchup', [
                                ...values.teamCultureCatchup,
                                data.value,
                              ])
                              : null
                            validateForm()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Field type='hidden' name='teamCultureCatchup' />
                </div>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center ${styles.borderTop}`}
              >
                <CustomButton
                  text={'Discard'}
                  icon={<RecycleBinIcon />}
                  iconSide={'left'}
                  className={styles.discardBtn}
                  onClick={() => onCloseModal(false, selectedAdminUser.length + selectedCultureCatchupUser.length + selectedInterViewUser.length + selectedManagerUser.length)}
                />
                <div
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                  <CustomButton
                    type={'submit'}
                    text={'Update Team'}
                    className={`ms-3 ${styles.searchBtn}`}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>

          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default AccountProfileModal
