import React from 'react'

interface Props {
  size?: number
  color?: string
}

const ClockIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ?? 20}
      fill='none'
      viewBox={'0 0 20 20'}
    >
      <path
        d='M10 5v5l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
        stroke={color ?? '#636363'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ClockIcon
