import React, { useEffect, useMemo, useState } from 'react'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import Settings from './settings'
import styles from '../teams/styles.module.scss'
import CustomButton from '../../shared/components/customButton/customButton'
import PlusIcon from '../../icons/plus.icon'
import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import EditIcon from '../../icons/edit.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import LoadingService from '../../shared/services/loading.service'
import {
  addMasterData,
  deleteMasterData,
  getMasterData,
  updateMasterData,
} from '../../shared/services/masterData.service'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import ConfirmBannerModal from '../../shared/components/errorBannerModal/confirmBannerModal'
import CustomModal from '../../shared/components/customModal/customModal'
import CustomInputField from '../../shared/components/customInputField/customInputField'
import { Form, Formik } from 'formik'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'


const costCalculationVariables = [
  'accounts_payable_multiplier',
  'accounts_payable_rate_pointer',
  'accounts_receivable_multiplier',
  'accounts_receivable_rate_pointer',
  'annual_leave_rate',
  'bas_role_pointer',
  'currency_fixed_cost',
  'senior_bookkeeper_rate',
  'bank_account_fixed_cost',
  'banking_multiplier',
  'banking_rate_pointer',
  'bas_lodgement_fixed_cost',
  'bas_multiplier',
  'bas_rate_pointer',
  'bookkeeper_rate',
  'cost_centre_fixed_cost',
  'eofy_role_pointer',
  'eom_multiplier',
  'eom_rate_pointer',
  'growth_rate',
  'ias_multiplier',
  'payroll_multiplier',
  'payroll_officer_rate',
  'payroll_rate_pointer',
  'payroll_tax_rate',
  'personal_leave_rate',
  'public_holiday_rate',
  'super_rate',
  'workers_compensation_rate',
]

function MasterData() {
  const navigate = useNavigate()
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<any[]>([])
  const [masterDataCopy, setMasterDataCopy] = useState<any[]>([])
  const [categories, setAllCategories] = useState<any[]>([])
  const [selectedData, setSelectedData] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState<any>(
    { name: '', value: '' })
  const [selectedCategoryDuplicate, setSelectedCategoryDuplicate] = useState<any>(
    { name: '', value: '' })
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [editModal, setEditModal] = useState(false)
  const [addModal, setAddModal] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate('/signIn')
    })

  }, [navigate])

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )

  function getSortedResult(array: any[]) {

    return array.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name)
    })
  }

  function getSortedData(array: any[]) {
    return array.sort((a: any, b: any) => {
      return a.category.localeCompare(b.category)
    })
  }

  const getData = () => {
    loadingService.await(
      getMasterData().then((data: any) => {
        data = data.filter((current: any) => !costCalculationVariables.includes(current.category))
        const withoutRanks = data.filter((a: any) => !(a?.category?.toString()?.endsWith('rank')))
        const ranks = data.filter((a: any) => a?.category?.toString()?.endsWith('rank'))
        const sorted = getSortedData(withoutRanks)
        setMasterData([...sorted, ...ranks])
        if (selectedData.category) {
          setMasterDataCopy([...sorted, ...ranks].filter((d: any) => d.category === selectedData.category))
        } else {
          setMasterDataCopy([...sorted, ...ranks])
        }
        setIsLoading(false)
      }),
    ).catch((error: any) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [loadingService])

  const filterData = (item: any) => {
    if (item.text) {
      setMasterDataCopy(masterData.filter((d: any) => d.category === item.text))
      setSelectedCategory({
        name: item.text,
        value: item.value,
      })
    } else if (item.category) {
      setMasterDataCopy(
        masterData.filter((d: any) => d.category === item.category),
      )
    } else {
      setMasterDataCopy(masterData)
    }
  }

  const showDeleteConfirmBox = async (value: string): Promise<void> => {
    setShowConfirm(true)
    setDeleteId(value)
  }

  const updateData = async (values: any): Promise<void> => {
    setIsLoading(true)
    const update = {
      id: selectedData.id,
      category: values.category,
      value: values.value,
    }
    await updateMasterData(update).then(() => {
      setEditModal(false)
      getData()
      filterData({ name: selectedData.category })

    }).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
    setSelectedCategory({ name: '', value: '' })
    setSelectedCategoryDuplicate({ name: '', value: '' })
    filterData(selectedData)
  }

  const addData = async (values: any): Promise<void> => {
    setIsLoading(true)
    const add = {
      category: selectedCategory.name,
      value: values.value,
    }
    await addMasterData(add).then(() => {
      setAddModal(false)
      getData()
      filterData(selectedData)
    }).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
    setSelectedCategory({ name: '', value: '' })
    setSelectedCategoryDuplicate({ name: '', value: '' })
  }

  const deleteData = async (value: string): Promise<void> => {
    setIsLoading(true)
    setShowConfirm(false)
    await deleteMasterData(value).then(() => {
      getData()
      setIsLoading(false)
      filterData(selectedData)
    }).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
    setDeleteId('')
  }

  useEffect(() => {
    if (categories) {
      let unique_values = masterData
        .map((item) => item.category)
        .filter(
          (value, index, current_value) =>
            current_value.indexOf(value) === index,
        )

      unique_values = unique_values.filter((current: any) => !costCalculationVariables.includes(current.category))
      const category = unique_values.map((match: any, key) => ({
        name: match,
        value: key,
      }))
      const withoutRanks = category.filter((a: any) => !(a?.name?.toString()?.endsWith('rank')))
      const ranks = category.filter((a: any) => a?.name?.toString()?.endsWith('rank'))
      const sorted = getSortedResult(withoutRanks)
      setAllCategories([...sorted, ...ranks])
    }
    // eslint-disable-next-line
  }, [masterData])
  const dateFormatter = (date: string) => {
    const d = date.split('-')
    return d[2] + '-' + d[1] + '-' + d[0]
  }

  const initialVal: any = useMemo(() => {
    return {
      category: selectedData?.category,
      value: selectedData?.value,
    }
  }, [selectedData])

  const initialValAdd: any = useMemo(() => {
    return {
      category: '',
      value: '',
    }
    // eslint-disable-next-line
  }, [selectedData])
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <TopNavigation tabValue={'0'} />
      <div className='d-md-flex justify-content-evenly mx-5 my-5'>
        <Settings masterDataSelected={true} />
        <div className={`mx-md-5 mx-1 col-sm-12 col-lg-8`}>
          <div>
            <div>
              <div className={styles.contentQuestion}>
                <div className={`w-100 ${styles.widthLeft}`}>
                  <div
                    className={
                      'd-md-flex w-100 justify-content-between align-items-center'
                    }
                  >
                    <h2 className={`text-bolder text-almostBlack`}>
                      Master Data
                    </h2>
                    <CustomButton
                      onClick={() => {
                        setAddModal(true)
                      }}
                      text={'Add New'}
                      icon={<PlusIcon />}
                      iconSide={'left'}
                      className={styles.addQuestionBtn}
                    />
                  </div>
                </div>
              </div>
              <div className={`mt-3 ${styles.searchBar}`}>
                <CustomDropdown
                  dataList={categories.map((d: any) => ({
                    text: d.name,
                    value: d.value,
                  }))}
                  getSelectedItem={(item: any) => filterData(item)}
                  placeHolder={'Category'}
                  selectedValue={{
                    value: selectedCategory?.value ?? '',
                    text: selectedCategory?.name ?? '',
                  }}
                />
              </div>
            </div>
            <table className={`mt-3 ${styles.table}`}>
              <tr>
                <th className={`${styles.head}`}>
                  <h6>Category</h6>
                </th>
                <th className={`${styles.head}`}>
                  <h6>Value</h6>
                </th>
                <th className={`${styles.head}`}>
                  <h6>Updated Time</h6>
                </th>
                <th className={`${styles.head}`}>
                  <h6>Action</h6>
                </th>
              </tr>
              {masterDataCopy.map((val, key) => {
                return (
                  <tr key={key}>
                    <td className={styles.tableContent}>
                      <h6 className={'gray-color-text'}>{val.category}</h6>
                    </td>
                    <td className={styles.tableContent}>
                      <h6 className={'gray-color-text'}>{val.value}</h6>
                    </td>
                    <td className={styles.tableContent}>
                      <h6 className={'gray-color-text'}>{dateFormatter(val.updated_at.slice(0, 10))}</h6>
                    </td>
                    <td className={styles.tableContent}>
                      <div className={'d-flex'}>
                        <div
                          className={'pointer'}
                          onClick={() => {
                            setSelectedData(val)
                            setEditModal(true)
                          }}
                        >
                          <EditIcon />
                        </div>
                        <div
                          className={'ms-3'}
                          onClick={() => showDeleteConfirmBox(val.id)}
                        >
                          <RecycleBinIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </table>
          </div>
        </div>
      </div>
      {editModal && (
        <CustomModal
          open={editModal}
          onCloseModal={(val: any) => setEditModal(val)}
          title={'Edit Master Data'}
        >
          <Formik initialValues={initialVal} onSubmit={updateData}>
            {({ values, setFieldValue }) => (
              <Form>
                <div
                  className={
                    'd-flex justify-content-between align-content-center'
                  }
                >
                  <div className={styles.dropdownWidth}>
                    <CustomDropdown
                      dataList={categories.map((d: any) => ({
                        text: d.name,
                        value: d.value,
                      }))}
                      placeHolder={'Category'}
                      selectedValue={
                        'Edit Master Data'
                          ? {
                            value: selectedData?.value ?? '',
                            text: selectedData?.category ?? '',
                          }
                          : categories[0]
                      }
                      getSelectedItem={(item: any) => {
                        setSelectedCategory(item)
                      }}
                    />
                  </div>

                  <CustomInputField
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('value', e.target.value)
                    }
                    name={'value'}
                    placeholder={'Value'}
                  />
                </div>
                <div
                  className={'d-flex justify-content-between align-content-center mt-3'}
                >
                  <CustomButton text={'Discard'} icon={<RecycleBinIcon />} onClick={() => {
                    setEditModal(false)
                    setSelectedCategory({ name: '', value: '' })
                    setSelectedCategoryDuplicate({ name: '', value: '' })
                  }} className={styles.discardBtn} />
                  <div className={'ms-3'}>
                    <CustomButton
                      text={'Save'}
                      type={'submit'}
                      disabled={isLoading || !(values?.value?.length)}
                      className={styles.widthBtn}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomModal>
      )}
      {addModal && (
        <CustomModal
          open={addModal}
          onCloseModal={(val: any) => setAddModal(val)}
          title={'Add Master Data'}
        >
          <Formik initialValues={initialValAdd} onSubmit={addData}>
            {({ values, setFieldValue }) => (
              <Form>
                <div
                  className={
                    'd-flex justify-content-between align-content-center'
                  }
                >
                  <div className={styles.dropdownWidth}>
                    <CustomDropdown
                      dataList={categories.map((d: any) => ({
                        text: d.name,
                        value: d.value,
                      }))}
                      placeHolder={'Category'}
                      getSelectedItem={(item: any) => {
                        setSelectedCategoryDuplicate({
                          name: item.text,
                          value: item.value,
                        })
                      }}
                      selectedValue={{
                        value: selectedCategoryDuplicate?.value ?? '',
                        text: selectedCategoryDuplicate?.name ?? '',
                      }}
                    />
                  </div>

                  <CustomInputField
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('value', e.target.value)
                    }
                    name={'value'}
                    placeholder={'Value'}
                  />
                </div>
                <div
                  className={'d-flex justify-content-between align-content-center mt-3'}
                >
                  <CustomButton className={styles.discardBtn} text={'Discard'} icon={<RecycleBinIcon />}
                                onClick={() => {
                                  setAddModal(false)
                                  setSelectedCategory({ name: '', value: '' })
                                  setSelectedCategoryDuplicate({ name: '', value: '' })
                                }} />
                  <div className={'ms-3'}>
                    <CustomButton
                      text={'Save'}
                      type={'submit'}
                      disabled={isLoading || !(values?.value?.length && selectedCategory?.name?.length)}
                      className={styles.widthBtn}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomModal>
      )}
      <ConfirmBannerModal
        onYesClick={() => deleteData(deleteId)}
        open={showConfirm}
        onClose={(close) => {
          setDeleteId('')
          setShowConfirm(close)
        }}
        title={'Delete Confirmation'}
        confirmMessage={'Are you sure you want to delete this master data record?'}
      />
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default MasterData
