import React from 'react'

interface Props {
  size?: number
  color?: string
}

const DegreeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 48}
      height={size ?? 48}
      fill='none'
      viewBox={'0 0 48 48'}
    >
      <rect
        width={size ?? 48}
        height={size ?? 48}
        rx={24}
        fill={color ?? '#F0F8FA'}
      />
      <path
        d='m34 21-10-4-10 4 10 4 10-4Zm0 0v6m-16-4.4V28c0 .796.632 1.559 1.757 2.121 1.126.563 2.652.88 4.243.88 1.591 0 3.117-.317 4.243-.88C29.368 29.56 30 28.796 30 28.001v-5.4'
        stroke='#49B0BD'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DegreeIcon
