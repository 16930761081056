import React, { useMemo, useState } from 'react'

import styles from './sinIn.module.scss'
import SignInImage from '../../../src/images/Professional Sign In.jpg'
import { Form, Formik } from 'formik'


import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Auth } from 'aws-amplify'

import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'

import ProfLogo from '../../images/BBB Logo_Admin.jpg'


import CustomInputField from '../../shared/components/customInputField/customInputField'
import CustomButton from '../../shared/components/customButton/customButton'
import CustomModal from '../../shared/components/customModal/customModal'
import LoadingService from '../../shared/services/loading.service'
import ForgotModal from '../../shared/components/forgotModal/forgotModal'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { validateEmailAddress } from '../../shared/services/user.service'
import ResetPasswordModal from './resetPasswordModal'


type SignInProps = {
  email: string;
  password: string;
  rememberMe: boolean;
}

function SignIn() {
  const navigation = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [forgot, setForgot] = useState(false)
  const [resetPassword, setRestPassword] = useState(false)
  const [cognitoUser, setCognitoUser] = useState<any>('')

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const initialVal: SignInProps = useMemo(() => {
    return {
      email: '',
      password: '',
      rememberMe: false,
    }
  }, [])

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address.').email('Please enter a valid email address.'),
    password: Yup.string().required('Please enter your password.'),
  })


  const handleSubmit = async function (values: SignInProps, { setFieldError }: { setFieldError: any }) {
    loadingService.setReason('signIn', true)
    try {
      const user = await Auth.signIn(values.email, values.password)
      setCognitoUser(user)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setRestPassword(true)
        setCognitoUser(user)
      } else {
        if (location.state?.id) {
          navigation('/dashboard?id=' + location.state?.id)
        } else {
          navigation('/dashboard')
        }

      }
    } catch (err: any) {
      if (err.name === 'NotAuthorizedException') {
        try {
          const validateRes = await validateEmailAddress(values.email)
          if (validateRes === null || validateRes.status !== 'success' || validateRes.result === false) {
            setFieldError('email', 'Account not found. If you haven\'t registered yet, please sign up for a new account.')
          } else if (err.message === 'User is disabled.') {
            setFieldError('password', 'This user has been deactivated.')
          } else {
            setFieldError('password', 'Incorrect password. Please try again.')
          }
        } catch (e: any) {
          setFieldError('email', e.message)
        }

      } else {
        setFieldError('email', 'Failed to sign in.')
      }
      loadingService.setReason('signIn', false)
    }
  }


  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik initialValues={initialVal} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Form>
              <div className={'d-flex justify-content-between w-100'}>
                <div className={styles.formContainer}>
                  <div className={styles.logo}>
                    <img src={ProfLogo} alt='react logo' className={styles.logoImage} />
                  </div>
                  <div className={styles.leftContentBox}>
                    <div className={styles.middleContent}>
                      <h1 className={'text-almostBlack text-bolder mt-3 mt-xl-0'}>Sign In</h1>
                      <h6 className={'mt-5 text-normal signInLineHeight'}>Welcome! You’re part of the BBB Team and we
                        value the part you play in helping to build our organisation. Sign in here using your BBB email
                        and password.</h6>
                      <div className={'mt-5'}>
                        <CustomInputField name={'email'}
                                          onChange={(event: any) => setFieldValue('email', event.target.value)}
                                          placeholder={'Email *'} />
                      </div>
                      <div className={'mt-3'}>
                        <CustomInputField type={'password'}
                                          onChange={(event: any) => setFieldValue('password', event.target.value)}
                                          placeholder={'Password *'} name={'password'} />
                      </div>

                      <div className={'d-flex justify-content-end w-100 mt-3'}>
                        <p className={'text-bold text-almostBlack pointer'}
                           onClick={() => setForgot(true)}>Forgot Password?</p>
                      </div>
                      <div className={'mt-4'}>
                        <CustomButton disabled={isLoading} type={'submit'} text={'Sign In with BBB'} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.imageContainer}>
                  <img src={SignInImage} alt={'signIn'} className={styles.image} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {forgot &&
        <CustomModal open={forgot} onCloseModal={(val: any) => setForgot(val)}>
          <ForgotModal />
        </CustomModal>
      }

      {resetPassword &&
        <CustomModal open={resetPassword} onCloseModal={(val: any) => setRestPassword(val)}>
          <ResetPasswordModal user={cognitoUser} />
        </CustomModal>
      }
    </div>
  )
}

export default SignIn
