import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import styles2 from './../jobCard/styles.module.scss'
import PlusIcon from '../../../icons/plus.icon'
import CustomModal from '../customModal/customModal'
import AccountProfileModal from '../filterModal/accountProfileModal'
import Avatar from '../../../images/avatar.png'
import colors from '../../../scss/variables.module.scss'
import { getHiringTeamMembers } from '../../services/teams.service'
import InfoIcon from '../../../icons/info.icon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type addTeamProp = {
  job_id: string
  disabled: boolean
  teamComplete?: (val: boolean) => void
}

function AddTeam({ job_id, disabled, teamComplete }: addTeamProp) {
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [modal, setModal] = useState(false)
  const [teamUser, setTeamUser] = useState<any[]>([])

  const loadHiringTeamData = useCallback(async () => {
    try {
      const data: any = await getHiringTeamMembers(job_id)
      if (data) {
        const userData = data?.map((md: any) => ({
          imageUrl: md.profile_image,
          userid: md.id,
        }))
        setTeamUser(userData)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    loadHiringTeamData().then()
    // eslint-disable-next-line
  }, [job_id, modal])

  return (
    <div className={'mt-3 mt-md-0 mb-3 mb-md-0'} key={`${job_id}`}>
      <h6
        className={`gray-color-text d-flex align-items-center ${styles.justifyEnd}`}
      >
        Hiring Team
      </h6>

      <div className={'d-flex align-items-center'}>
        {teamUser.map((item, index) => (
          <img key={`${item.id}${index}`}
               className={styles.imageRound}
               src={item.imageUrl ?? Avatar}
          ></img>
        ))}
        {teamUser.length < 6 && (<div className={`mt-2 mt-md-0 ${styles2.errorBox}`}>
          <InfoIcon color={colors.dangerColor} />
          <h6 className={'secondary-color-text ms-3'}>
            Add Hiring Team
          </h6>
        </div>)}


        <button style={{ zIndex: 1000 }}
                disabled={disabled}
                className={`ms-3 ${styles.addTeam}`}
                onClick={(e) => {
                  e.stopPropagation()
                  setModal(true)
                }}
        >
          <PlusIcon />
        </button>
      </div>
      {
        modal && (
          <CustomModal
            open={modal}
            onCloseModal={(val: any) => setModal(val)}
            title={'Team'}
          >
            <AccountProfileModal
              job_id={job_id}
              onCloseModal={(val: boolean, count: number) => {
                setModal(val)
                if (teamComplete) {
                  teamComplete(count === 6)
                }

              }}
            />
          </CustomModal>
        )
      }
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default AddTeam
