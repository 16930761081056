import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import styles from './../../shared/components/filterModal/styles.module.scss'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import CustomButton from '../../shared/components/customButton/customButton'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CustomInputField from '../../shared/components/customInputField/customInputField'
import CustomImageUpload from '../../shared/components/customImageUpload/customImageUpload'
import FileService from '../../shared/services/file.service'
import TeamsService, { updateTeamMember } from '../../shared/services/teams.service'
import * as Yup from 'yup'
import { TeamMember } from '../../shared/interfaces/teams.interface'
import { getMasterData } from '../../shared/services/masterData.service'
import LoadingService from '../../shared/services/loading.service'
import { checkEmailExists, validateEmailAddress } from '../../shared/services/user.service'
import 'yup-phone'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

export type CustomModalProps = {
  onCloseModal: (value: boolean) => void
  member?: TeamMember | null
  setReload?: (value: boolean) => void
  isEdit: boolean
}

export const bbbTeamList = [
  { value: 'a811b0f4-6936-4c7a-a8b9-d1c7e892e2bc', text: 'B. Effective' },
  { value: 'e9d34b6e-82f7-4d9f-a830-9d448d12674f', text: 'B. Reliable' },
  { value: '7f8e2b43-1e99-4a93-8b5d-6d39b1dfe2fa', text: 'B. Supportive' },
  { value: 'a1b2c3d4-5e6f-7a8b-9c0d-1e2f3a4b5c6d', text: 'Non-Team' },
]

function AddNewMember({ onCloseModal, member, setReload, isEdit }: CustomModalProps) {
  const [loading, setLoading] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [masterData, setMasterData] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File | null>()
  const [bbbTeamSelected, setBbbTeamSelected] = useState<string>('')
  const [buttonText, setButtonText] = useState<string>('Add Member')
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const teamsService: TeamsService = useMemo(() => {
    return new TeamsService()
  }, [])
  const fileService: FileService = useMemo(() => {
    return new FileService()
  }, [])
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setLoading)
  }, [])

  const [initialVal, setInitialVal] = useState(() => {
    return {
      id: '',
      name: '',
      phone: '',
      position: '',
      role: '',
      email: '',
      profile_url: '',
      team: '',
    }
  })

  const loadMemberData = useCallback(async () => {
    setLoading(true)
    try {
      if (member) {
        setInitialVal({
          id: member.cognito_id,
          name: member.full_name,
          phone: member.mobile_number ?? '',
          position: member.position,
          role: member.__role__.role,
          email: member.email,
          profile_url: member.profile_image_key ?? '',
          team: bbbTeamList.find((item) => item.value === member.team)?.text ?? '',
        })
        setButtonText('Update Member')
        setBbbTeamSelected(bbbTeamList.find((item) => item.value === member.team)?.text ?? '')
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }, [member])

  useEffect(() => {
    loadingService.await(getMasterData()).then(setMasterData).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (member) loadMemberData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMemberData])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().matches(/^(?:\+61\d{9}|04\d{8})$/, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX').required('Please enter your contact number.').phone('AU', false, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX.')
      .phone('AU', false, 'Please enter a valid phone number.'),
    position: Yup.string().required('Position is required'),
    role: Yup.string().required('Role is required'),
    team: Yup.string().required('Team is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  })

  const handleSubmit = async (value: FormikValues, { setFieldError }: FormikHelpers<any>) => {
    try {
      setLoading(true)
      const checkEmail = await checkEmailExists(value.email)

      if (member) {
        if (value.email !== member.email && checkEmail.success) {
          setFieldError('email', 'Email already exists')
          setLoading(false)
          return
        } else {
          const res = await updateTeamMember({
            ...value,
            team: bbbTeamList.find((item) => item.text === value.team)?.value,
          })
          if (res) {
            onCloseModal(res)
            setLoading(false) // Disable loading immediately
            if (setReload) {
              setReload(true)
            }
          } else {
            setErrorModel(true)
            setErrorMessage('Error..')
            setLoading(false)
          }
          return
        }
      }
      if (checkEmail.success) {
        setFieldError('email', 'Email already exists')
        setLoading(false)
        return
      }
      const status = await teamsService.addTeamMember({
        ...value,
        team: bbbTeamList.find((item) => item.text === value.team)?.value,
      })
      if (status) {
        setLoading(false)
        if (setReload) {
          setReload(true)
        }
        onCloseModal(status)
      } else {
        setErrorModel(true)
        setErrorMessage('Something went wrong. Please contact admin')
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }

  }

  const roleList = [
    { value: 'admin', text: 'Global Admin' },
    { value: 'manager', text: 'Manager' },
    { value: 'staff', text: 'Staff Admin' },
  ]
  return (
    <>
      <Formik
        initialValues={initialVal}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, errors }) => (
          <Form className={styles.responsive}>
            <div>
              <div className={'mt-3 d-md-flex justify-content-between'}>
                <CustomImageUpload
                  id={'image'}
                  fileSelect={async (e: any) => {
                    setFile(e)
                    setFieldValue('file', await fileService.readFile(e))
                  }}
                  defaultFile={values.profile_url}
                />
                <div className={'ms-md-4 mt-2 mt-md-0'}>
                  <CustomInputField
                    name={'name'}
                    placeholder={'Name *'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('name', event.target.value)
                    }
                    value={values.name}
                  />
                  <div className={`mt-4 d-md-flex`}>
                    <div className={`ms-md-2 ${styles.levelDropDown}`}>
                      <CustomDropdown
                        dataList={masterData
                          .filter((item: any) => item.category === 'positions')
                          .sort((a: any, b: any) => a.order - b.order)
                          .map((item: any) => ({
                            value: item.value,
                            text: item.value,
                          }))}
                        placeHolder={'Position *'}
                        onChange={(event: any) => {
                          setFieldValue('position', event)
                        }}
                        getSelectedItem={(select: any) => {
                          setFieldValue('position', select.value)
                        }}
                        selectedValue={{
                          value: values.position,
                          text: values.position,
                        }}
                      />
                      <div>
                        {errors.position && (
                          <div
                            className={styles.yupError}
                            style={{ marginTop: '-20px', position: 'relative' }}
                          >
                            {errors.position}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`ms-md-2 mt-2 mt-md-0 ${styles.levelDropDown}`}>
                      <CustomDropdown disabled={isEdit}
                                      dataList={roleList}
                                      placeHolder={'Access Level *'}
                                      onChange={(event: any) => {
                                        setFieldValue('role', event)
                                      }}
                                      getSelectedItem={(select: any) => {
                                        setFieldValue('role', select.value)
                                      }}
                                      selectedValue={{
                                        value: values.role,
                                        text: values.role === 'admin' ? 'Global Admin' : values.role === 'staff' ? 'Staff Admin' : values.role === 'manager' ? 'Manager' : '',
                                      }}
                      />
                      <div>
                        {errors.role && (
                          <div
                            className={styles.yupError}
                            style={{ marginTop: '-20px', position: 'relative' }}
                          >
                            {errors.role}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={`mt-3 d-md-flex`}>
                    <div className={`ms-2 mt-3 ${styles.levelDropDown}`}>
                      <CustomDropdown
                        dataList={bbbTeamList}
                        placeHolder={'BBB Team *'}
                        onChange={(event: any) => {
                          setFieldValue('team', event)
                        }}
                        getSelectedItem={(select: any) => {
                          setFieldValue('team', select.text)
                          setBbbTeamSelected(select.text)
                        }}
                        selectedValue={{
                          value: bbbTeamList.find((item) => item.text === bbbTeamSelected)?.value ?? '',
                          text: bbbTeamSelected,
                        }}
                      />
                      <div>
                        {errors.team && (
                          <div
                            className={styles.yupError}
                            style={{ marginTop: '-20px', position: 'relative' }}
                          >
                            {errors.team}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`ms-md-2 mt-3 ${styles.levelDropDown}`}>
                      <CustomInputField
                        name={'phone'}
                        placeholder={'Mobile Phone *'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('phone', event.target.value)
                        }
                      />
                    </div>
                  </div>
                  <CustomInputField disabled={isEdit}
                                    className={'mb-2 ms-md-1 mt-4'}
                                    name={'email'}
                                    placeholder={'Email *'}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                      setFieldValue('email', event.target.value)
                                    }
                  />
                  <div>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center ${styles.borderTop}`}
              >
                <CustomButton
                  text={'Discard'}
                  icon={<RecycleBinIcon />}
                  iconSide={'left'}
                  className={styles.discardBtn}
                  onClick={() => onCloseModal(true)}
                />
                <div
                  className={'d-flex justify-content-between align-items-center'}
                >
                  <CustomButton
                    type={'submit'}
                    text={buttonText}
                    className={`ms-3 ${styles.searchBtn}`}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>

  )
}

export default AddNewMember
