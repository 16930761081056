import React from 'react'

interface Props {
  size?: number
  color?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PercentageIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 14}
      height={size ?? 14}
      fill='none'
      viewBox={'0 0 14 14'}
    >
      <path
        stroke={color ?? '#374151'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M1 13 13 1m0 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM3 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z'
      />
    </svg>
  )
}

export default PercentageIcon
