import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import CustomButton from '../../shared/components/customButton/customButton'
import PlusIcon from '../../icons/plus.icon'
import EmailIcon from '../../icons/email.icon'
import BagIcon from '../../icons/bag.icon'
import PhoneIcon from '../../icons/phone.icon'
import color from '../../scss/variables.module.scss'
import EditIcon from '../../icons/edit.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import AddNewMember, { bbbTeamList } from './addNewMember'
import CustomModal from '../../shared/components/customModal/customModal'
import { TeamMember, UserTypes } from '../../shared/interfaces/teams.interface'
import LoadingService from '../../shared/services/loading.service'
import TeamsService, { getTeamMembers } from '../../shared/services/teams.service'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import defaultProfileImage from '../../images/avatar.png'
import ProfileImage from '../../shared/profileImage/ProfileImage'
import SuccessBannerModal from '../../shared/components/errorBannerModal/successBannerModal'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import { useNavigate } from 'react-router-dom'
import PersonalInfoIcon from '../../icons/personalInfo.icon'
import SearchDropdown from '../../shared/components/customDropdown/searchDropdown'
import ConfirmBannerModal from '../../shared/components/errorBannerModal/confirmBannerModal'
import { Auth } from 'aws-amplify'

function Teams() {
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const [activeStatusIndex, setActiveStatusIndex] = useState('0')
  const [successMessage, setSuccessMessage] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [navigateAfterError, setNavigateAfterError] = useState<string | null>(
    null,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([])
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null)
  const [filteredType, setFilteredType] = useState<'admin' | 'staff' | 'other' | 'all'>('all')
  const [reload, setReload] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')


  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate('/signIn')
    })

  }, [navigate])

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )
  // Filter the bbbTeamList based on searchValue
  const filteredBbbTeamList = bbbTeamList.filter((item: any) =>
    item.text.toLowerCase().includes(searchValue?.toLowerCase()),
  )

  const teamsService: TeamsService = useMemo(() => {
    return new TeamsService()
  }, [])
  // If the user does not have access to this page,
  // redirect them to the home page
  const loadMemberData = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = await getTeamMembers('all')
      if (data) {
        setTeamMembers(data)
        setIsLoading(false)
        setReload(false)
      }
    } catch (error: any) {
      setErrorMessage(error.message)
      setIsLoading(false)
      setTeamMembers([])
      setReload(false)
    }
  }, [])

  const statusList = [
    { value: '0', text: 'All' },
    { value: '1', text: 'Global Admin' },
    { value: '2', text: 'Manager' },
    { value: '3', text: 'Staff Admin' },

  ]

  const sortedTeamMembers = teamMembers.sort((a, b) => {
    if (a.status === 'active' && b.status !== 'active') {
      return -1
    }
    if (a.status !== 'active' && b.status === 'active') {
      return 1
    }
    // If both members have the same status, sort them alphabetically
    return a.full_name.localeCompare(b.full_name)
  })

  useEffect(() => {
    loadMemberData()
  }, [loadMemberData, reload, searchValue])

  // Instead of using the filteredTeamMembers variable directly, use the filteredType state variable
  const displayedTeamMembers = sortedTeamMembers.filter((member) =>
    filteredType === UserTypes.All
      ? sortedTeamMembers
      : filteredType === UserTypes.Other
        ? member.__role__.role === UserTypes.Manager
        : member.__role__.role === filteredType,
  )

  // Filter the teamMembers array based on the search text
  const filteredTeamMembers = displayedTeamMembers.filter((member) => {
    const fullNameMatch = member.full_name
      ?.toLowerCase()
      .includes(searchValue?.toLowerCase())
    const emailMatch = member.email
      .toLowerCase()
      ?.includes(searchValue?.toLowerCase())
    const positionMatch = member.position
      ? member.position.toLowerCase().includes(searchValue?.toLowerCase())
      : ''
    const teamMatch =
      member.team &&
      filteredBbbTeamList.some((item: any) => item.value === member.team)

    // Use logical OR (||) to return an empty string when mobile_number or address is null
    const mobileNumberMatch = member.mobile_number
      ? member.mobile_number.toLowerCase().includes(searchValue.toLowerCase())
      : ''
    const addressMatch = member.address
      ? member.address.toLowerCase().includes(searchValue.toLowerCase())
      : ''

    return (
      fullNameMatch ||
      emailMatch ||
      positionMatch ||
      mobileNumberMatch ||
      addressMatch ||
      teamMatch
    )
  })

  const handleRemoveMember = async (value: string) => {
    setShowConfirm(false)
    loadingService.await(
      teamsService
        .inactivateTeamMember(value)
        .then((res) => {
          if (res) {
            setSuccessMessage(true)
            loadMemberData()
          } else {
            setNavigateAfterError('/teams')
          }
        }),
    ).catch((error) => {
      setErrorMessage(error.message)
    })
  }

  const getRole = useCallback((role: string) => {
    switch (role) {
      case UserTypes.Admin:
        return 'Global Admin'
      case UserTypes.Manager:
        return 'Manager'
      case UserTypes.Staff:
        return 'Staff Admin'
      default:
        return 'Other'
    }
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}></div>
      <div className='w-100'>
        <TopNavigation tabValue={'5'} />
        <div className={`${styles.teams} `}>
          <div className={`d-flex flex-column`}>
            <div className='d-flex align-items-center justify-content-between'>
              <h2 className='text-almostBlack text-bolder'>Team</h2>
              <CustomButton
                text={'Add New Team Member'}
                type='button'
                onClick={() => {
                  setSelectedMember(null)
                  setModal(true)
                }}
                className={styles.addBtn}
                variant={'transparent'}
                icon={<PlusIcon />}
                iconSide={'left'}
              />
            </div>

            <div className='d-md-flex align-items-center justify-content-between mt-5 mb-0'>
              <span className='d-flex'>
                {statusList.map((val, index) => (
                  <button
                    key={index}
                    className={
                      activeStatusIndex === `${index}`
                        ? styles.activeBtn
                        : styles.btn
                    }
                    onClick={() => {
                      setActiveStatusIndex(val.value)
                      setFilteredType(
                        val.text.toLowerCase() === 'global admin' ? 'admin' : val.text.toLowerCase() === 'staff admin' ? 'staff' : val.text.toLowerCase() as
                          | 'admin'
                          | 'staff'
                          | 'other'
                          | 'all',
                      )
                    }}
                  >
                    <h6 className={'gray-color-text'}>{val.text}</h6>
                  </button>
                ))}
              </span>
              <div className={`d-flex mt-3 mt-md-0`}>
                <div className={styles.searchWidth}>
                  <SearchDropdown
                    team
                    dataList={[]}
                    placeHolder={'Search by Name, E-mail, Mobile Phone, Position and BBB Team'}
                    handleSearch={setSearchValue}
                  />
                </div>
              </div>
            </div>
          </div>
          {filteredTeamMembers.map((item: TeamMember, index: number) => (
            <div className='mt-3' key={index}>
              <div
                className={styles.card}
                style={{ borderColor: color.gray200, overflow: 'auto' }}
              >
                <div
                  className={
                    'd-md-flex justify-content-between align-items-start w-100'
                  }
                >
                  <div className={'d-md-flex align-items-center'}>
                    <div className='align-self-stretch me-5'>
                      <ProfileImage
                        src={item.profile_image_key ?? defaultProfileImage}
                        alt={'avatar'}
                      />
                    </div>

                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center'>
                        <h5 className={'text-almostBlack'}>{item.full_name}</h5>
                        <div
                          className={styles.payableTag}
                          style={
                            item.__role__.role === 'admin'
                              ? {
                                backgroundColor: color.successColor50,
                                color: color.successColor500,
                              }
                              : {
                                backgroundColor: color.orangeColor50,
                                color: color.orangeColor,
                              }
                          }
                        >
                          <h6>{getRole(item.__role__.role)}</h6>
                        </div>
                        <div
                          className={styles.payableTag}
                          style={
                            item.status === 'active'
                              ? {
                                backgroundColor: color.successColor50,
                                color: color.successColor500,
                              }
                              : {
                                backgroundColor: color.dangerColor,
                                color: color.blackColor,
                              }
                          }
                        >
                          <h6>{item.status}</h6>
                        </div>
                      </div>

                      <div className={'d-md-flex mt-4'}>
                        <div className={'d-flex me-4'}>
                          <div className={styles.iconBox}>
                            <BagIcon />
                          </div>

                          <h6 className={'ms-3 gray-color-text'}>
                            {item.position}
                          </h6>
                        </div>
                        <div className={`mt-2 mt-md-0 ${styles.borderClass}`}>
                          <div className={styles.iconBox}>
                            <EmailIcon size={20} />
                          </div>

                          <h6 className={'ms-3 gray-color-text'}>
                            {item.email}
                          </h6>
                        </div>
                        <div className={`mt-2 mt-md-0 ${styles.borderClass}`}>
                          <div className={styles.iconBox}>
                            <PhoneIcon />
                          </div>

                          <h6 className={'ms-3 gray-color-text'}>
                            {item.mobile_number}
                          </h6>
                        </div>
                        <div className={'d-flex ms-md-4 mt-2 mt-md-0'}>
                          <div className={styles.iconBox}>
                            <PersonalInfoIcon color={color.grayColor} />
                          </div>

                          <h6 className={'ms-3 gray-color-text'}>
                            {bbbTeamList.filter((val) => val.value === item.team)[0]?.text}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.status !== 'inactive' && (
                    <div
                      className='d-flex justify-content-end justify-content-md-start align-items-md-center mt-2 mt-md-0'>
                      <div
                        className='me-5'
                        onClick={() => {
                          setDeleteId(item.cognito_id)
                          setShowConfirm(true)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <RecycleBinIcon size={19} />
                      </div>
                      <div
                        onClick={() => {
                          setSelectedMember(item)
                          setModal(true)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <EditIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {modal && (
          <CustomModal
            open={modal}
            onCloseModal={(val: any) => setModal(val)}
            title={selectedMember ? 'Edit Team Member' : 'Add New Team Member'}
          >
            <AddNewMember isEdit={selectedMember ? true : false}
                          onCloseModal={(val) => setModal(!val)}
                          member={selectedMember}
                          setReload={setReload}
            />
          </CustomModal>
        )}
        <ErrorBannerModal
          open={errorMessage !== ''}
          onClose={() => {
            if (navigateAfterError !== null) {
              navigate(navigateAfterError)
            } else {
              setErrorMessage('')
            }
          }}
          errorMessage={errorMessage}
        />
        <SuccessBannerModal
          open={successMessage}
          onClose={() => navigate('/teams')}
          title={'Success'}
          Message={'User Inactivated!'}
        />
      </div>
      <ConfirmBannerModal
        onYesClick={() => handleRemoveMember(deleteId)}
        open={showConfirm}
        onClose={(close) => {
          setShowConfirm(close)
        }}
        title={'Delete Confirmation'}
        confirmMessage={'Are you sure you want to make this user inactive?'}
      />
    </>
  )
}

export default Teams
