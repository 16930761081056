import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import OrganizationFilter from './organizationFilter'

import { getOrganizationsFilter } from '../../shared/services/organization.service'
import { IOrganization } from '../../shared/interfaces/organization.interface'
import LoadingService from '../../shared/services/loading.service'
import { DropdownDataList } from '../../shared/components/avatarDropdown/avatarDropdown'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { getMasterData } from '../../shared/services/masterData.service'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

function Organizations() {
  // State variables
  const navigate = useNavigate()
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [selectedData, setSelectedData] = useState<IOrganization | null>(null)
  const [selectedIndustry, setSelectedIndustry] = useState<string>('')
  const [selectedOrganizationType, setOrganizationType] = useState<string>('')
  const [selectedRefereeRelationType, setSelectedRefereeRelationType] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [orgStatus, setOrgStatus] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<any[]>([])
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [counter, setCounter] = useState<number>(0)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [howDidYouHear, setHowDidYouHear] = useState<string>('')

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate('/signIn')
    })

  }, [navigate])
  /**
   * Dropdown data list for status
   * @type {DropdownDataList[]}
   * value is a boolean since the status of an organization is a boolean will be easier to update the status
   */
  const statusList: DropdownDataList[] = [
    { value: '0', text: 'Active' },
    { value: '1', text: 'Inactive' },
  ]

  // Loading manager
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )

  /**
   * Handles the "Load More" button click event.
   * If loadMore is false, it increments the pageNumber state by 1.
   */
  const handleLoadMore = () => {
    if (!loadMore) {
      setPageNumber(pageNumber + 1)
    }
  }

  /**
   * Fetches organizations based on the search query and page number.
   * @param {string} [searchQuery] - The search query to filter organizations by name.
   * @returns {void}
   */
  const fetchOrganizations = useCallback(
    (searchQuery?: string): void => {
      const lowercaseSearchQuery = searchQuery?.toLowerCase()
      loadingService
        .await(getOrganizationsFilter({
          page: pageNumber,
          search: lowercaseSearchQuery ?? '',
        }))
        .then((res) => {
          if (res.length < 10) {
            setLoadMore(true)
          }
          if ((selectedData !== organizations[0] && (selectedData?.id !== '' && organizations[0]?.id)) && counter > 0) {
            setOrganizations(res.filter((org: any) => org.name.toLowerCase().includes(selectedData?.name.toLowerCase())))
          } else {
            setCounter(counter + 1)
            if (searchQuery && searchQuery.length > 0) {
              // If there is a search query, filter organizations based on the query
              const filteredOrganizations = res.filter((org) =>
                org.name.toLowerCase().includes(lowercaseSearchQuery ?? ''),
              )
              setOrganizations(filteredOrganizations)
            } else {
              // Remove organizations with names matching the search query
              const filteredOrganizations = res.filter((org) => !lowercaseSearchQuery || !org.name.toLowerCase().includes(lowercaseSearchQuery))
              setSelectedData(counter === 0 ? filteredOrganizations[0] : selectedData)
              setOrganizations(() =>
                [...filteredOrganizations, ...organizations]
                  .filter(
                    (item, index, self) =>
                      index === self.findIndex((t) => t.id === item.id),
                  ),
              )
            }
          }
        }).catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingService, pageNumber],
  )

  /**
   * Set the selected organization data
   * Handles event from the child component
   * @param data The organization data to be selected
   * @returns void
   */
  const onDataSelection = (data: IOrganization): void => {
    setSelectedData(data)
    setOrgStatus(data.active)
  }

  /**
   * Search organizations based on the search query
   * Handles event from the child component
   * @param searchQuery The search query to filter the organizations
   * @returns void
   */
  const onDataSearch = (searchQuery: string): void => {
    fetchOrganizations(searchQuery)
    setPageNumber(0)
  }

  // Fetch organizations on component mount
  useEffect(() => {
    fetchOrganizations()
    return () => setOrganizations([])
  }, [fetchOrganizations])

  useEffect(() => {
    loadingService.await(getMasterData()).then(setMasterData).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }, [loadingService])

  useEffect(() => {
    setSelectedIndustry(masterData
      .filter((d) => d.category === 'organization_industry')
      .find((d) => d.id === selectedData?.industry)?.value)
    setSelectedRefereeRelationType(masterData
      .filter((d) => d.category === 'organization_relationship')
      .find((d) => d.id === selectedData?.user_relationship)?.value)
    setOrganizationType(masterData
      .filter((d) => d.category === 'org_type')
      .find((d) => d.id === selectedData?.type)?.value)
    if (selectedData?.how_did_you_hear_about_us) {
      setHowDidYouHear(masterData
        .filter((d) => d.category === 'how_did_you_hear_about_us')
        .find((d) => d.id === selectedData?.how_did_you_hear_about_us)?.value)
    }


  }, [masterData, selectedData])

  return (
    <div>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        <TopNavigation tabValue={'3'} />
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <OrganizationFilter
              disabled={isLoading}
              organizations={organizations}
              onSelection={onDataSelection}
              onSearch={onDataSearch}
              loadMore={handleLoadMore}
              setLoadMore={setLoadMore}
              resetPage={() => setPageNumber(0)}
              handleSearchValue={setSelectedData}
            />
          </div>
          {selectedData && (
            <div className={styles.rightWidth}>
              <div className={styles.responsive}>
                <div className={styles.contentQuestion}>
                  <h4
                    className={`text-bolder text-almostBlack ${styles.widthLeft}`}
                  >
                    {selectedData?.name}
                  </h4>
                </div>
              </div>
              {selectedData.referring_user_name && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Referral Name</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedData.referring_user_name}</h6>
                    </div>
                  </div>
                </div>
              )}
              {selectedData.referring_user_mobile && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Referral Mobile</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedData.referring_user_mobile}</h6>
                    </div>
                  </div>
                </div>
              )}
              {selectedData.referring_user_email && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Referral Email</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedData.referring_user_email}</h6>
                    </div>
                  </div>
                </div>
              )}
              {/*Referral Company Name*/}
              {selectedData.referring_company_name && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Referral Company Name</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedData.referring_company_name ?? ''}</h6>
                    </div>
                  </div>
                </div>
              )}

              {selectedData?.user_relationship && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Referral Relationship</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedRefereeRelationType}</h6>
                    </div>
                  </div>
                </div>
              )}
              {selectedData.parent_user_name && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Main Contact Name</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedData.parent_user_name}</h6>
                    </div>
                  </div>
                </div>
              )}
              {selectedData?.phone && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Main Contact Mobile</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}>{selectedData?.phone}</h6>
                    </div>
                  </div>
                </div>
              )}
              {selectedData?.email && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Main Contact Email</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}> {selectedData?.email}</h6>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className={styles.contentQuestion}>
                  <h6 className={`gray-color-text ${styles.widthLeft}`}>Web Address</h6>
                  <div className={`${styles.answerLength} `}>
                    <h6 className={'text-gray-600'}>{selectedData?.website}</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.contentQuestion}>
                  <h6 className={`gray-color-text ${styles.widthLeft}`}>Organisation Address</h6>
                  <div className={`${styles.answerLength}`}>
                    <h6 className={'text-gray-600'}> {selectedData?.address}</h6>

                  </div>
                </div>
              </div>
              <div>
                <div className={styles.contentQuestion}>
                  <h6 className={`gray-color-text ${styles.widthLeft}`}>Industry ANZSIC code</h6>
                  <div className={`${styles.answerLength}`}>
                    <h6 className={'text-gray-600'}>{selectedIndustry}</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.contentQuestion}>
                  <h6 className={`gray-color-text ${styles.widthLeft}`}>Organisation Type</h6>
                  <div className={`${styles.answerLength}`}>
                    <h6 className={'text-gray-600'}>{selectedOrganizationType}</h6>
                  </div>
                </div>
              </div>
              {selectedData?.how_did_you_hear_about_us && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>How did you hear about us </h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}> {howDidYouHear}</h6>
                    </div>
                  </div>
                </div>
              )}

              {selectedData?.referral_text && (
                <div>
                  <div className={styles.contentQuestion}>
                    <h6 className={`gray-color-text ${styles.widthLeft}`}>Referral text</h6>
                    <div className={`${styles.answerLength}`}>
                      <h6 className={'text-gray-600'}> {selectedData?.referral_text}</h6>
                    </div>
                  </div>
                </div>
              )}

            </div>
          )}
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Organizations
