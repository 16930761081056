import { RequestStatus } from '../enums/commonApplication.enum'
import { ApproveJobCost, JobCostDetails, JobDetails, JobSearchParams } from '../interfaces/job.interface'
import { axiosInstance } from './base.service'

export const getJobCount = async (since?: string): Promise<number> => {
  if (!since) {
    since = new Date(0).toISOString()
  }
  const url: string = `/jobs/total?start_date=${since}&end_date=${new Date().toISOString()}`
  try {
    const res = await axiosInstance.get<{ status: string; result: number }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}

export const getJobs = async (
  params: JobSearchParams,
): Promise<JobDetails[]> => {
  const url: string = `/jobs/filter?${new URLSearchParams(params)}`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: JobDetails[]
    }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}

export const getJobCostDetails = async (
  id: string,
): Promise<JobCostDetails> => {
  const url: string = `/jobs/cost/get?${new URLSearchParams({ id })}`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: JobCostDetails
    }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}
export const getRefereeComments = async (candidateID: string): Promise<any> => {
  const url: string = `jobs/get/candidate/referee/comments?${new URLSearchParams(
    { candidateID },
  )}`
  try {
    const res = await axiosInstance.get<{
      status: string
      result: JobCostDetails
    }>(url)
    return res.data.result

  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}

export const getJobLocationDetails = async (): Promise<any> => {
  const url: string = `/jobs/job_locations`
  try {
    const res = await axiosInstance.get<{ status: string; result: any }>(url)
    return res.data.result

  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}


export const approveCostPerMonth = async (
  question: ApproveJobCost,
): Promise<boolean> => {
  const url: string = `/jobs/cost/approve`

  try {
    const res = await axiosInstance.post<{
      status: string
      result: ApproveJobCost
    }>(url, question)
    return res.data.status === RequestStatus.Success
  } catch (err) {

    return false
  }
}

/**
 * Using this to get task expertise skills for JobInner component
 * @param  params { id: string }
 */
export const getJobSkills = async (jobId: string): Promise<any> => {
  const params = {
    id: jobId,
  }
  const url: string = `/jobs/job_skills?${new URLSearchParams(
    params,
  )}`

  try {
    const res = await axiosInstance.get<{ status: string; result: any }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }
}


export const getCloseJobCount = async (since?: string): Promise<number> => {
  if (!since) {
    since = new Date(0).toISOString()
  }
  const url: string = `/jobs/closed_total?start_date=${since}&end_date=${new Date().toISOString()}`
  try {
    const res = await axiosInstance.get<{ status: string; result: number }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}

export const getInactiveJobCount = async (since?: string): Promise<number> => {
  if (!since) {
    since = new Date(0).toISOString()
  }
  const url: string = `/jobs/inactive_total?start_date=${since}&end_date=${new Date().toISOString()}`
  try {
    const res = await axiosInstance.get<{ status: string; result: number }>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}


