import styles from '../../../pages/dashboard/Backdrop.module.scss'
import BagIcon from '../../../icons/bag.icon'
import colors from '../../../scss/variables.module.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { getMasterData } from '../../services/masterData.service'
import LoadingService from '../../services/loading.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface WorkExperienceProps {
  disabled: boolean;
  data: any[] | undefined
}

const WorkExperience = ({ data }: WorkExperienceProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<any[]>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )
  useEffect(() => {
    loadingService.await(getMasterData()).then((res: any) => {
      setMasterData(res.filter((fd: any) => fd.category === 'finance_jobs'))
    }).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }, [loadingService])


  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
        >
          <div className={'d-flex align-items-center mb-3'}>
            <BagIcon color={colors.blackColor} />
            <h4 className={'text-almostBlack ms-3 text-bolder'}>Work Experience</h4>
          </div>
        </div>
        <div className={'d-flex align-items-center w-100 flex-wrap mt-3'}>
          <div className={styles.answerLength}>
            <div className={'d-flex'}>
              <h6 className={`text-normal gray-color-text w-50`}>Role Title</h6>
              <h6 className={`text-normal gray-color-text w-50`}>Relevant Experience</h6>
            </div>
            {data?.map((value, index) => (
              <div key={`work-experience-${index}`} className={styles.answer}>
                <div className={'d-flex gap-4'}>
                  <h6
                    className={`${styles.jobExperienceText}`}>{masterData.filter((fd) => fd.id === value.role)[0]?.value ?? value.role}</h6>
                  <h6 className={`${styles.jobExperienceText}`}>{value.years_of_experience}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default WorkExperience
