import React, { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import PersonalInfoIcon from '../../../icons/personalInfo.icon'
import EditIcon from '../../../icons/edit.icon'
import { Form, Formik } from 'formik'
import CustomButton from '../customButton/customButton'
import CloseIcon from '../../../icons/close.icon'
import * as Yup from 'yup'
import LoadingService from '../../services/loading.service'
import { updateCandidateOverview } from '../../services/candidate.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export interface OverviewProfileProps {
  disabled?: boolean
  overview?: string
  lastUpdatedBy?: string
  lastUpdatedAt?: string
  candidateId?: string
  onUpdateOverview?: (overview: {
    overview: string
    overview_last_updated_at: string
    overview_last_updated_by: string
  }) => void
}

const Overview = ({
  disabled,
  overview,
  lastUpdatedAt,
  candidateId,
  onUpdateOverview,
}: OverviewProfileProps) => {
  const [editable, setEditable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(disabled)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    [],
  )

  useEffect(() => {
    setIsDisabled(disabled || isLoading)
  }, [disabled, isLoading])

  const validationSchema = Yup.object().shape({
    overview: Yup.string().required('The overview cannot be blank.'),
  })

  function handleSubmit(values: { overview: string }) {
    if (!candidateId) return
    loadingService
      .await(updateCandidateOverview(candidateId, values.overview))
      .then((success: boolean) => {
        if (!success) {
          setErrorModel(true)
          setErrorMessage('Error Update')
          return
        }
        if (onUpdateOverview) {
          onUpdateOverview({
            overview: values.overview,
            overview_last_updated_at: new Date().toISOString(),
            overview_last_updated_by: 'you',
          })
        }
        setEditable(false)
      })
  }

  return (
    <div style={{ opacity: isDisabled ? 0.5 : 1 }}>
      <Formik
        initialValues={{ overview: overview ?? '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, errors, resetForm, setFieldValue }) => (
          <Form>
            <div
              className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
            >
              <div className={'d-flex align-items-center  mb-3'}>
                <PersonalInfoIcon />
                <h4 className={'text-almostBlack ms-3 text-bolder'}>
                  Overview
                </h4>
              </div>
              <div className={'d-flex'}>
                <div>
                  {!editable && (
                    <div onClick={() => setEditable(true)}>
                      <EditIcon />
                    </div>
                  )}
                  {editable && (
                    <div
                      onClick={() => {
                        resetForm()
                        setEditable(false)
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className='d-flex mt-3'>
                <textarea
                  className={`${!editable ? styles.noBorder : ''} ${
                    styles.width100
                  } ${styles.fontSize}`}
                  disabled={!editable}
                  rows={8}
                  value={values.overview}
                  onChange={(e) => {
                    if (!isDisabled) {
                      setFieldValue('overview', e.target.value)
                    }
                  }}
                />
              </div>

              {lastUpdatedAt && (
                <p>
                  Last updated on{' '}
                  {lastUpdatedAt &&
                    (() => {
                      const dateObj = new Date(lastUpdatedAt)
                      const day = dateObj.getDate().toString().padStart(2, '0')
                      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
                      const year = dateObj.getFullYear()
                      return `${day}-${month}-${year}`
                    })()
                  }
                </p>
              )}
              <p>Powered by GPT-4.</p>
            </div>
            {editable && (
              <div className={styles.bottomContent}>
                <CustomButton
                  text={'Save Changes'}
                  disabled={isDisabled || !!errors.overview}
                  loading={isLoading}
                  type={'submit'}
                  className={styles.saveBtn}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Overview
