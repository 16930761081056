import React, { Children, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'

import { motion } from 'framer-motion'
import { v4 as uuidv4 } from 'uuid'

import styles from './TabBox.module.scss'

type TabBoxButtonProps = {
  id?: string
  children: ReactNode
  tabName: string
  active?: boolean
  onTabClick?: (tabName: string) => void
  __TYPE?: string
  border?: boolean
  layoutId?: string
  notificationCount?: number
  disabled?: boolean
}

type TabContentProps = {
  children: ReactNode
  tabName: string
  __TYPE: string
}

type TabsProps = {
  __TYPE: string
  tabName?: string
  children: ReactElement<TabBoxButtonProps> | ReactElement<TabBoxButtonProps>[]
}

type TabBoxProps = {
  className?: string
  selectedTabName: string
  shadow?: boolean
  onTabChange?: (tabName: string) => void
  children:
  | ReactElement<TabsProps | TabContentProps>
  | ReactElement<TabContentProps>[]
  layoutId?: string
}

const types = {
  TAB_BOX_BUTTON: 'TAB_BOX_BUTTON',
  TABS: 'TABS',
  TAB_CONTENT: 'TAB_CONTENT',
}

export const TabBoxButton = ({
  id = uuidv4(),
  tabName,
  children,
  onTabClick,
  active = false,
  border = false,
  notificationCount = 0,
  layoutId,
  disabled = false,
}: TabBoxButtonProps) => {
  const handleClick = () => {
    if (!disabled) {
      onTabClick?.(tabName)
    }
  }
  return (
    <>
      {border ? (
        <div
          className={[
            active ? styles.tabBoxHeaderActive : '',
            disabled ? styles.disabledTab : styles.tabBoxHeaderTab, // Add styling for disabled tab
          ].join(' ')}
          id={id}
          onClick={handleClick}
        >
          {children}
          {notificationCount > 0 && (
            <div className={styles.notificationCircle}>
              <span className={styles.notificationCount}>
                {notificationCount}
              </span>
            </div>
          )}
          {active ? (
            <motion.div className={styles.underline} layoutId={layoutId} />
          ) : null}
        </div>
      ) : (
        <div
          className={[
            active ? styles.tabBoxHeaderActive : '',
            disabled ? styles.disabledTab : styles.tabBoxHeaderTab, // Add styling for disabled tab
          ].join(' ')}
          id={id}
          onClick={handleClick}
        >
          {children}
          {active ? (
            <motion.div
              className={styles.underline}
              layoutId={layoutId}
              id={id}
            />
          ) : null}
        </div>
      )}
    </>
  )
}

const TabBox = ({
  shadow,
  children,
  className,
  selectedTabName,
  onTabChange,
  layoutId = 'underline',
}: TabBoxProps) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabName)
  const tabs = useRef<ReactElement<TabBoxButtonProps>[]>([])
  const tabContent = useRef<ReactElement<TabContentProps>[]>([])

  const handleTabClick = (clickedTab: string) => {
    setSelectedTab(clickedTab)
    onTabChange?.(clickedTab)
  }

  useEffect(() => {
    setSelectedTab(selectedTabName)
  }, [selectedTabName])

  const addOrReplace = (obj: ReactElement<TabsProps | TabContentProps>) => {
    const index = tabContent.current.findIndex(
      (item: ReactElement<TabsProps | TabContentProps>) => {
        return item.props.tabName === obj.props.tabName
      },
    )

    if (index === -1) {
      tabContent.current.push(obj as ReactElement<TabContentProps>)
    }
  }

  Children.forEach(children, (child) => {
    switch (child.props.__TYPE) {
      case types.TABS:
        if (Array.isArray(child.props.children)) {
          tabs.current = child.props.children.filter(
            (
              item:
              | ReactElement<TabBoxButtonProps>
              | ReactElement<TabBoxButtonProps>[],
            ) => {
              if (item) {
                return item
              } else {
                return null
              }
            },
          )
        }
        break
      case types.TAB_CONTENT:
        addOrReplace(child)
        break
      default:
        return <div></div>
    }
  })

  return (
    <div className={[styles.tabBox, className].join(' ')}>
      <div className={styles.tabBoxHeader}>
        {tabs.current.map(({ props }: ReactElement<TabBoxButtonProps>) => {
          if (props) {
            return (
              <TabBoxButton
                key={uuidv4()}
                tabName={props?.tabName}
                active={selectedTab === props?.tabName}
                onTabClick={handleTabClick}
                border={props?.border}
                layoutId={layoutId}
                notificationCount={props?.notificationCount}
                disabled={props?.disabled}
              >
                {props?.children}
              </TabBoxButton>
            )
          } else {
            return null
          }
        })}
      </div>
      <div
        className={[styles.tabBoxContent, shadow ? styles.showShadow : ''].join(
          ' ',
        )}
      >
        {tabContent.current.filter((child: ReactElement<TabContentProps>) => {
          return child.props.tabName !== selectedTab
            ? undefined
            : child.props.children
        })}
      </div>
    </div>
  )
}

TabBoxButton.defaultProps = {
  __TYPE: types.TAB_BOX_BUTTON,
}

export const Tabs = ({ children }: TabsProps) => <>{children}</>

Tabs.defaultProps = {
  __TYPE: types.TABS,
}

export const TabContent = ({ tabName, children }: TabContentProps) => {
  return (
    <div aria-label={tabName} className={styles.tabBoxContent}>
      {children}
    </div>
  )
}

TabContent.defaultProps = {
  __TYPE: types.TAB_CONTENT,
}

export default TabBox
