import React from 'react'

interface Props {
  size?: number
  color?: string
}

const PinIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 24}
      height={size ?? 24}
      fill='none'
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 4v6l-2 4v2h10v-2l-2-4V4m-3 12v5M8 4h8'
      />
    </svg>
  )
}

export default PinIcon
