import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import template from '../../images/template.png'
import React from 'react'
// @ts-ignore
import OpenSansBold from '../../fonts/Calibri Bold/Calibri Bold.TTF'
// @ts-ignore
import ArialBold from '../../fonts/Calibri Bold/arial_bold.ttf'

export interface JPassInvoice {
  overView?: string
  skillSummary?: string
  systemsExperience?: []
  EducationExperience?: []
  profile_url?: any,
  name?: string
}

const disableHyphenation = (word: any) => [word]
Font.registerHyphenationCallback(disableHyphenation)

Font.register({
  family: 'OpenSans',
  fonts: [

    {
      src: OpenSansBold,
      fontWeight: 700,
    },
  ],
})
Font.register({
  family: 'ArialBold',
  fonts: [

    {
      src: ArialBold,
      fontWeight: 700,
    },
  ],
})
const JPassInvoice = (props: JPassInvoice) => {
  const styles = StyleSheet.create({
    absoluteHeader: {
      backgroundColor: '#fff',
      position: 'absolute',
      top: 86,
      right: 90,
      width: '200px',
    },
    headerText: {
      fontFamily: 'Helvetica-Bold',
    },
    nameText: {

      fontFamily: 'OpenSans',
      color: '#fff',
      fontSize: 18,

    },
    nameContent: {
      position: 'absolute',
      width: '145px',
      top: 585,
      left: 17,
      flexWrap: 'wrap',
    },
    absoluteImage: {
      position: 'absolute',
      top: 554.5,
      left: 160,
    },
    image: {
      width: '150px',
      height: '150px',
      objectFit: 'cover',
    },
    overview: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: 'ArialBold',
      textDecoration: 'underline',
    },
    skill: {
      fontFamily: 'ArialBold',
      fontSize: 12,
      fontWeight: 700,
      textDecoration: 'underline',
      marginBottom: '10px,',
    },
    overviewContent: {
      width: 230,
      backgroundColor: '#fff',
      padding: 20,
      paddingBottom: 10,
      position: 'absolute',
      top: 180,
      left: 50,
    },
    overviewContent2: {
      width: 230,
      backgroundColor: '#fff',
      padding: 20,
      paddingBottom: 10,
      position: 'absolute',
      top: 180,
      left: 320,
    },
    skillsContent: {
      marginTop: 10,
    },
    subOverview: {
      fontSize: 10,
      marginTop: '10px',
    },
    skillText: {
      fontSize: 10,
      marginTop: 5,
      marginLeft: 4,
    },
    page: {
      flexDirection: 'row', // Set to 'row' for two-page layout
      backgroundColor: '#fff',
    },
    imageBack: {
      width: '595px',
      height: '841px',
    },
  })
  return (
    <Document pageLayout={'singlePage'}>
      <Page size='A4' style={styles.page}>
        <View>
          <Image src={template} style={styles.imageBack} />
        </View>
        <View style={styles.absoluteHeader}>
          <Text style={styles.headerText}>TEAM PROFILE</Text>
        </View>
        <View style={styles.overviewContent}>
          <Text style={styles.overview}>Overview</Text>
          <Text style={styles.subOverview}>
            {props?.overView?.slice(0, 1020)}

          </Text>
        </View>
        <View style={styles.overviewContent2}>
          <Text style={styles.skill}>Key Skills Summary </Text>
          {props?.skillSummary
            ?.split('.')
            .map((s: string) => s.trim())
            .slice(0, 5)
            .filter((s: string) => s !== '')
            .map((value, index) => (
              <View
                key={index}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Text>•</Text>
                <Text style={styles.skillText}>
                  {value}
                </Text>
              </View>
            ))}

          <View style={styles.skillsContent}>
            <Text style={styles.skill}>Systems Experience </Text>
            {props?.systemsExperience?.slice(0, 5).map((value: any, index) => (
              <View
                key={index}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Text>•</Text>
                <Text style={styles.skillText}>{value}</Text>
              </View>
            ))}
          </View>
          <View style={styles.skillsContent}>
            <Text style={styles.skill}>Education/Qualifications </Text>
            {props?.EducationExperience?.slice(0, 3).map((value: any, index) => (
              <View
                key={index}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Text>•</Text>
                {value.institution ?
                  <Text style={styles.skillText}>
                    {value.field_of_study} - {value.institution}
                  </Text>
                  :
                  <Text style={styles.skillText}>
                    {value.field_of_study}
                  </Text>
                }
              </View>
            ))}
          </View>
        </View>
        <View style={styles.absoluteImage}>

          <Image src={{
            uri: props.profile_url, method: 'GET', body: '', headers: {
              'Cache-Control': 'no-store',
              'Pragma': 'no-cache',
              'Connection': 'keep-alive',
              'Accept': '*/*',
              'Accept-Encoding': 'gzip, deflate, br',
              'Sec-Fetch-Mode': 'cors',
              'Sec-Fetch-Site': 'cross-site',
              'Sec-Fetch-Dest': 'empty',
              'Expires': 0,
            },
          }} style={styles.image} />
        </View>
        <View style={styles.nameContent}>
          <Text style={styles.nameText}>{props.name}</Text>
          <Text style={styles.nameText}>Finance Professional</Text>
        </View>
      </Page>
    </Document>
  )
}

export default JPassInvoice
