import { axiosInstance } from './base.service'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import { RequestStatus } from '../enums/commonApplication.enum'


export const uploadPaperWork = async (form: any): Promise<any> => {
  const url: string = `/paper_work/upload_files`
  try {
    const formData = new FormData()
    formData.append('file', form?.file?.original)
    formData.append('user_id', form?.data.uId)
    formData.append('hiring_process_id', form?.data.hId)
    formData.append('file_name', form?.file?.name)
    formData.append('file_type', form.data.fileType)
    const res: any = await axiosInstance.post<BaseResponse<boolean>>(
      url,
      formData,
    )
    return res.data.status === RequestStatus.Success
  } catch (e) {
    return false
  }

}

export const deletePaperWork = async (id: string): Promise<any> => {
  const url: string = `/paper_work/delete_files?id=${id}`

  try {
    const res = await axiosInstance.post<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}

export const getPaperWorks = async (hiringProcessId: string): Promise<any> => {
  const url: string = `/paper_work/get_files?hiring_process_id=${hiringProcessId}`

  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
  }

}
