import React from 'react'
import styles from './styles.module.scss'
import BagIcon from '../../../icons/bag.icon'
import colors from '../../../scss/variables.module.scss'

interface ExpertiseProps {
  taskExpertise?: string[]
  systemExpertise?: string[]
  industryExpertise?: string[]
}

function Expertise({
  taskExpertise,
  systemExpertise,
  industryExpertise,
}: ExpertiseProps) {

  const tagBoxStyle = {
    width: '100%',
    marginTop: '35px',
  }

  return (
    <div>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
      >
        <div className={'d-flex align-items-center mb-3'}>
          <BagIcon color={colors.blackColor} />
          <h4 className={'text-almostBlack ms-3 text-bolder'}>Expertise</h4>
        </div>
      </div>
      <div className={'d-flex align-items-center w-100 flex-wrap mt-3'}>
        {taskExpertise && (
          <div style={tagBoxStyle}>
            <h5>Task Expertise</h5>
            <div className='d-flex mt-3 flex-wrap'>
              {taskExpertise.map((value, index) => (
                <div key={`task-expertise-${index}`} className={styles.payableTag}>
                  <h6>{value}</h6>
                </div>
              ))}
            </div>
          </div>
        )}
        {systemExpertise && (
          <div style={tagBoxStyle}>
            <h5>System Expertise</h5>
            <div className='d-flex mt-3 flex-wrap'>
              {systemExpertise.map((value, index) => (
                <div key={`system-expertise-${index}`} className={styles.payableTag}>
                  <h6>{value}</h6>
                </div>
              ))}
            </div>
          </div>
        )}
        {industryExpertise && (
          <div style={tagBoxStyle}>
            <h5>Industry Expertise</h5>
            <div className='d-flex mt-3 flex-wrap'>
              {industryExpertise.map((value, index) => (
                <div key={`industry-expertise-${index}`} className={styles.payableTag}>
                  <h6>{value}</h6>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Expertise
