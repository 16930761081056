import React from 'react'

interface Props {
  size?: number
  color?: string
}

const WrongIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 14}
      fill='none'
      viewBox={'0 0 14 14'}
    >
      <path
        d='M13 1 1 13M1 1l12 12'
        stroke={color ?? '#636363'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default WrongIcon
