import React from 'react'

interface Props {
  size?: number
  color?: string
}

const CameraIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ?? 18}
      fill='none'
      viewBox={'0 0 20 18'}
    >
      <path
        d='M3 4h1a2 2 0 0 0 2-2 1 1 0 0 1 1-1h6a1 1 0 0 1 1 1 2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Z'
        stroke={color ?? '#1B454B'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
        stroke='#1B454B'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CameraIcon
