import React from 'react'

interface Props {
  size?: number
  color?: string
}

const HeartIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 21}
      height={size ? size - 2 : 19}
      fill='none'
      viewBox={'0 0 21 19'}
    >
      <path
        stroke={color ?? '#1F1F1F'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M18.5 10.572 11 18l-7.5-7.428A5 5 0 1 1 11 4.006a5 5 0 1 1 7.5 6.572'
      />
    </svg>
  )
}

export default HeartIcon
