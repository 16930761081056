import React, { useEffect, useMemo, useState } from 'react'
import styles from './Backdrop.module.scss'
import BagIcon from '../../icons/bag.icon'
import WalkingManIcon from '../../icons/walkingMan.icon'
import CustomButton from '../../shared/components/customButton/customButton'
import DegreeCapIcon from '../../icons/degreeCap.icon'
import DescriptionIcon from '../../icons/discription.icon'
import Location from '../../icons/location.icon'
import colors from '../../scss/variables.module.scss'
import SendIcon from '../../icons/send.icon'
import { CloseButton } from 'react-bootstrap'
import CostIcon from '../../icons/cost.icon'
import { JobDetails } from '../../shared/interfaces/job.interface'
import { getMasterData } from '../../shared/services/masterData.service'
import LoadingService from '../../shared/services/loading.service'
import ResumeIcon from '../../icons/resume.icon'
import { getCandidateFiles, getHiringProcesses } from '../../shared/services/hiring.service'
import { UploadedFile } from '../../shared/types/file.model'
import fileStyles from '../../shared/components/candidateInfo/styles.module.scss'
import * as _ from 'lodash'
import { getJobSkills } from '../../shared/services/job.service'
import CalenderIcon from '../../icons/calender.icon'
import PinIcon from '../../icons/pin.icon'
import { HiringStage } from '../../shared/enums/hiring.enum'
import { useLocation } from 'react-router-dom'
import { JobStatus, WorkCommitment } from '../../shared/enums/job.enum'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

export type JobInnerProps = {
  details?: JobDetails;
  onClose: (value: boolean) => void;
};

function JobInner({ onClose, details }: JobInnerProps) {
  const location = useLocation()
  const [jobStatus, setJobStatus] = useState<string>('')
  const [masterData, setMasterData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jobDesc, setJobDesc] = useState<UploadedFile | null>(null)
  const [jobSkills, setJobSkills] = useState<any>([])
  const [educationCategoryID, setEducationCategoryID] = useState<string>('')
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    loadingService.await(getMasterData()).then(setMasterData).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }, [loadingService])

  const findStatus = (data: any) => {
    const Completed = data.filter((a: any) => a.stage === HiringStage.Completed).length
    const PaperWork = data.filter((a: any) => a.stage === HiringStage.PaperWork).length
    const Negotiation = data.filter((a: any) => a.stage === HiringStage.Negotiation).length
    const ReferenceCheck = data.filter((a: any) => a.stage === HiringStage.ReferenceCheck).length
    const Interview = data.filter((a: any) => a.stage === HiringStage.Interview).length
    const CultureCatchUp = data.filter((a: any) => a.stage === HiringStage.CultureCatchUp).length
    const Screening = data.filter((a: any) => a.stage === HiringStage.Screening).length
    if (Completed) {
      setJobStatus('Completed')
    } else if (PaperWork) {
      setJobStatus('Paper Work')
    } else if (Negotiation) {
      setJobStatus('Negotiation')
    } else if (ReferenceCheck) {
      setJobStatus('Reference Check')
    } else if (Interview) {
      setJobStatus('Interview')
    } else if (CultureCatchUp) {
      setJobStatus('Culture Catch Up')
    } else if (Screening) {
      setJobStatus('Screening')
    }
  }

  useEffect(() => {
    if (location?.state?.job) {
      loadingService.await(getHiringProcesses(location.state.job.id)).then((da: any) => {
        findStatus(da)
      }).catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    }
  }, [loadingService, location.state.job])
  useEffect(() => {
    setIsLoading(true)
    if (details) {
      if (details.job_description_key) {
        const jobDesc_key = details.job_description_key?.split('/')
        const jobDesc_url: any = {
          name: jobDesc_key[2],
          contents: '',
          type: 'url',
        }
        setJobDesc(jobDesc_url)
        setIsLoading(false)
      }
      loadingService.await(getJobSkills(details.id)).then(setJobSkills).catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
        setIsLoading(false)
      })
      setEducationCategoryID(details.minimum_qualification ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details])

  const openResumePDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (details) {
        const params = {
          id: details.id,
          URL_Type: 'job_desc',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }

  }

  return (
    <>
      <div className={styles.backdrop}>
        <div className={styles.card}>
          <div
            className={'d-flex justify-content-between align-items-center mt-5'}
          >
            <h4 className={'text-bolder text-almostBlack'}>{details?.title}</h4>
            {(details && details.jobs_status === JobStatus.Inactive) && <h6
              className={`${styles.inactiveTag} mr-3`}>{details.jobs_status.charAt(0).toUpperCase() + details.jobs_status.slice(1)}</h6>}
            {jobStatus && <div className={styles.payableTag}>
              <h6 className={'text-success'}>{jobStatus}</h6>
            </div>}
            <CustomButton
              onClick={() => onClose(false)}
              icon={<CloseButton color={colors.teritaryColor500} />}
              iconSide={'right'}
              text={'Close'}
              className={styles.closeBtn}
            />

          </div>

          <div className={'d-flex flex-wrap'}>
            {jobSkills?.taskExpertise?.map((item: string, index: number) => (
              <div style={{ marginRight: '5px', marginBottom: '5px' }} key={`txpt${index}`}>
                <div key={`task-expertise-${index}`} className={styles.payableTag}>
                  <h6>{item}</h6>
                </div>
              </div>
            ))}
          </div>
          <div className={`d-flex mt-5 ${styles.borderBottom}`}>
            <div className={'d-flex me-4'}>
              <BagIcon />
              <h6 className={'ms-3 gray-color-text'}>
                {details?.commitment === WorkCommitment.PermanentFullTime ? 'Full Time' :
                  details?.commitment === WorkCommitment.PermanentPartTime ? 'Part Time' :
                    details?.commitment === WorkCommitment.Casual ? 'Casual' :
                      'Unknown Commitment'
                }
              </h6>
            </div>
            <div className={styles.borderClass}>
              <WalkingManIcon />
              <h6 className={'ms-3 gray-color-text'}>{details?.work_type}</h6>
            </div>
            <div className={`d-flex ms-4 ${styles.borderRight}`}>
              <Location />
              <h6 className={'ms-3 gray-color-text'}>{details?.location}</h6>
            </div>
            <div className={`d-flex ms-4`}>
              <PinIcon />
              <h6
                className={'ms-3 gray-color-text'}>{masterData.filter((d: any) => d.category === 'finance_jobs').find((i: any) => i.id === details?.category)?.value}</h6>
            </div>
          </div>
          <div
            className={`mt-5 d-flex justify-content-between ${styles.borderBottom}`}
          >
            <div className={'d-flex align-items-center mb-3'}>
              <CostIcon />
              <h6 className={'ms-3 gray-color-text '}>
                Role Cost
                <span className={'darkgray-text ms-2'}>
                    ${(() => {
                  const num = Math.round((details?.approved_cost_per_month ?? 0.00) * 100) / 100
                  const str = num.toString()
                  const parts = str.split('.')
                  if (parts.length < 2) {
                    return str + '.00'
                  }
                  if (parts[1].length < 2) {
                    return str + '0'
                  }
                  return str
                })()}/month
                  </span>
              </h6>
            </div>
            <h6 className={'gray-color-text'}>
              {details?.weekly_work_hours} hrs per week
            </h6>
          </div>
          <div className={`mt-5 ${styles.borderBottom}`}>
            <div className={'d-flex align-items-center mb-3'}>
              <DegreeCapIcon />
              <h6 className={'ms-3 gray-color-text '}>Education</h6>
            </div>
            <h6 className={'darkgray-text'}>
              {
                masterData
                  .filter((fd: any) => fd.category === 'qualification')
                  .find(
                    (fd: any) => fd.id === educationCategoryID,
                  )?.value
              }
            </h6>
          </div>
          <div className={`mt-5 ${styles.borderBottom}`}>
            <div className={'d-flex align-items-center mb-3'}>
              <BagIcon />
              <h6 className={'ms-3 gray-color-text '}>System Expertise</h6>
            </div>
            {jobSkills?.systemExpertise?.map((item: string, index: number) => (
              <div style={{ marginRight: '4px', marginBottom: '4px' }} key={`txpt${index}`}>
                <div key={`task-expertise-${index}`} className={styles.payableTag}>
                  <h6>{item}</h6>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.borderBottom}>
            <div className={`mt-5 d-flex w-75 justify-content-between`}>
              <div>
                <div className={'d-flex align-items-center mb-3'}>
                  <BagIcon color={colors.textLightColor} />
                  <h6 className={'ms-3 gray-color-text '}>Experience</h6>
                </div>
                <h6 className={'darkgray-text'}>
                  {details?.minimum_years_of_experience} Years
                </h6>
              </div>
              <div>
                <div className={'d-flex align-items-center mb-3'}>
                  <SendIcon color={colors.textLightColor} />
                  <h6 className={'ms-3 gray-color-text '}>Start In</h6>
                </div>
                <h6 className={'darkgray-text'}>
                  <h6 className={'darkgray-text'}>
                    {details && details.start_date &&
                      (() => {
                        const dateObj = new Date(details.start_date)
                        const day = dateObj.getDate().toString().padStart(2, '0')
                        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
                        const year = dateObj.getFullYear()
                        return `${day}-${month}-${year}`
                      })()
                    }
                  </h6>
                </h6>
              </div>

              <div>
                <div className={'d-flex align-items-center mb-3'}>
                  <CalenderIcon color={colors.textLightColor} />
                  <h6 className={'ms-3 gray-color-text '}>Job Posted Date</h6>
                </div>
                <h6 className={'darkgray-text'}>
                  <h6 className={'darkgray-text'}>
                    {details && details.created_at &&
                      (() => {
                        const dateObj = new Date(details.created_at)
                        const day = dateObj.getDate().toString().padStart(2, '0')
                        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
                        const year = dateObj.getFullYear()
                        return `${day}-${month}-${year}`
                      })()
                    }
                  </h6>
                </h6>
              </div>

            </div>
          </div>
          <div className={`mt-5`}>
            <div className={'d-flex align-items-center'}>
              <DescriptionIcon />
              <h6 className={'ms-3 gray-color-text'}>Role Description</h6>
            </div>
            <h6 className={'darkgray-text text-normal mt-5 mb-5'}>
              {details?.job_description}
            </h6>
            <p>Powered by GPT-4.</p>
          </div>
          <div className='mt-5'>
            <div className={'d-flex align-items-center mb-3'}>
              <ResumeIcon />
              <h6 className={'ms-3 gray-color-text'}>
                Role Description File
              </h6>
            </div>
            {jobDesc && (
              <div className={fileStyles.selectedResume}>
                <h6 className={'ms-3 text-bold gray-color-text'}>
                  Selected File
                </h6>

                <div className={`d-flex ${fileStyles.borderTop}`}>
                  <div
                    className={
                      'd-flex justify-content-between align-items-center w-100'
                    }
                  >
                    <h6
                      className={
                        'text-normal gray-color-text d-flex align-items-center w-100'
                      }
                    >
                        <span className={styles.ellipse}>
                          {jobDesc ? jobDesc.name : ''}
                        </span>{' '}
                      <span
                        // hidden={isLoading}
                        className={isLoading ? styles.clickedLink : styles.link}
                        onClick={openResumePDFInNewTab}
                      >
                          <h6 className={'ms-0 ms-md-3'}>View File</h6>
                        </span>
                    </h6>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default JobInner
